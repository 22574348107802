import "./styles.css";
import {
  Layout,
  Card,
  Row,
  Col,
  Input,
  Form,
  Button,
  Typography,
  Image,
  Space,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { dashboardRoute } from "../../../routes/navRoutes";
import Logo from "../../../assets/images/logo/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getHouseholdsAction } from "../../../redux/Households";
import { setLoadingAction } from "../../../redux/General/Loading";
import { RootState } from "../../../redux/store";

const { Content } = Layout;
const { Text, Title } = Typography;

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loading: boolean = useSelector(
    (state: RootState) => state.loadingReducer
  );
  const [userInfo, setUserInfo] = useState({ email: "", password: "" });

  useEffect(() => {
    dispatch(setLoadingAction(false));
  }, []);

  const onFinish = async () => {
    if (
      userInfo.email === "info@advicefront.com" &&
      userInfo.password === "asd123"
    ) {
      await dispatch(getHouseholdsAction());
      history.push(dashboardRoute);
    } else {
      alert("Invalid credentials");
    }
  };

  return (
    <Layout className="layout" style={{ backgroundColor: "white" }}>
      <Content>
        <Row justify="center">
          <Card style={{ marginTop: "10%", width: "500px" }} bordered={false}>
            <Row justify="center">
              <Image src={Logo} width={100} preview={false} />
            </Row>
            <Row justify="center" style={{ marginTop: 20 }}>
              <Space direction="vertical" size={-5} align="center">
                <Title level={4}>Log in to your account</Title>
                <Text type="secondary">Enter your credentials below</Text>
              </Space>
            </Row>
            <Row justify="center" style={{ marginTop: 20 }}>
              <Col span={24}>
                <Form layout="vertical" size="large" onFinish={onFinish}>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: "Email is required" }]}
                  >
                    <Input
                      placeholder="Email"
                      prefix={<UserOutlined type="secondary" />}
                      type="email"
                      name="email"
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: "Password is required" },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      size="large"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      prefix={<LockOutlined />}
                      name="password"
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      size="large"
                      block
                      loading={loading}
                    >
                      Sign in
                    </Button>
                    <Row style={{ marginTop: "10px" }}>
                      <Col span={12}>
                        <Row justify="start">
                          <Link to="" type="link">
                            Forgot password?
                          </Link>
                        </Row>
                      </Col>
                      {/* <Col span={12}>
                        <Row justify="end">
                          <Link to={registerFormRoute} type="link">
                            Create account
                          </Link>
                        </Row>
                      </Col> */}
                    </Row>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </Row>
      </Content>
    </Layout>
  );
};

export default Login;
