import { IFlow } from "../../../interface/IFlow";

function calcInflow(year: number, yearIndex: number, inflow: IFlow) {
  let amount = 0;
  if (year >= inflow.startYear && year <= inflow.endYear) {
    amount = inflow.amount * (1 + inflow.growth) ** yearIndex;
  }

  return amount;
}

export default calcInflow;
