import {
  Button,
  Card,
  Row,
  Space,
  Switch,
  Table,
  Form,
  Input,
  InputNumber,
  Col,
  DatePicker,
} from "antd";
//@ts-ignore
import formulajs from "@formulajs/formulajs";
import Layout from "antd/lib/layout/layout";
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import IData from "../../../interface/IData";
import Modal from "antd/lib/modal/Modal";
import { IFlow } from "../../../interface/IFlow";
import moment from "moment";
import IPlan from "../../../interface/IPlan";
import axios from "axios";
import { apiPlanRoute } from "../../../routes/apiRoutes";
import { getDataAction } from "../../../redux/Data";
import { setLoadingAction } from "../../../redux/General/Loading";
import { IAsset } from "../../../interface/IAsset";
import { ILiability } from "../../../interface/ILiability";
import { numberFormat } from "highcharts";
import { currencySymbol } from "../../../components/common/currencySymbol";
import {
  CreateAssetInputDto,
  UpdateAssetInputDto,
} from "@advicefront/plan-api";
import {
  createInputsAssetAction,
  deleteInputsAcion,
  updateInputsAction,
} from "../../../redux/Inputs";
import calcRate from "../../../helper/data/formulae/calcRate";
import calcLoanPv from "../../../helper/data/formulae/calcLoanPv";

const NonLiquidAssets = () => {
  const dispatch = useDispatch();

  const activePlan: IPlan = useSelector(
    (state: RootStateOrAny) => state.activePlanReducer
  );
  const data: IData = useSelector((state: RootStateOrAny) => state.dataReducer);
  const loading: boolean = useSelector(
    (state: RootStateOrAny) => state.loadingReducer
  );

  const [newModalVisible, setNewModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);

  const [newAssetObject, setNewAssetObject] = useState({
    name: "",
    amount: 0,
    growth: 0,
    startYear: 2021,
    endYear: 2021,
    sellInFuture: false,
    sellAmount: 0,
    sellDate: 2021,
    rented: false,
    rentAmount: 0,
    rentGrowth: 0,
    rentStart: 2021,
    assetOnLoan: false,
    loanAmount: 0,
    loanPayment: 0,
    loanStartYear: 2021,
    loanEndYear: 2021,
    assetLoanAmount: 0,
  });

  const [editAssetObject, setEditAssetObject] = useState({
    name: "",
    amount: 0,
    growth: 0,
    startYear: 2021,
    endYear: 2021,
    sellInFuture: false,
    sellAmount: 0,
    sellDate: 2021,
    rented: false,
    rentAmount: 0,
    rentGrowth: 0,
    rentStart: 2021,
    assetOnLoan: false,
    loanAmount: 0,
    loanPayment: 0,
    loanStartYear: 2021,
    loanEndYear: 2021,
    assetLoanAmount: 0,
  });

  const [assetObjectForm] = Form.useForm();

  const columns = [
    { title: "Name", dataIndex: "label", key: "name" },
    {
      title: "Market Value",
      dataIndex: "currentValue",
      key: "amount",
      render: (record: number) =>
        currencySymbol + numberFormat(record, 0, ".", ","),
    },
    {
      title: "Growth",
      dataIndex: "growth",
      key: "growth",
      render: (record: number) => numberFormat(record * 100, 2, ".", ",") + "%",
    },
    { title: "Start Year", dataIndex: "startYear", key: "startYear" },
    { title: "End Year", dataIndex: "endYear", key: "endYear" },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (value: IAsset, record: any, recordIndex: number) => (
        <>
          <a
            onClick={() => {
              setEditAssetObject({
                name: data.inputs.assets[recordIndex].label,
                amount: data.inputs.assets[recordIndex].asset.currentValue,
                growth: data.inputs.assets[recordIndex].asset.growth,
                startYear: data.inputs.assets[recordIndex].asset.startYear,
                endYear: data.inputs.assets[recordIndex].asset.endYear,
                sellInFuture:
                  data.inputs.assets[recordIndex].assetSale!.label !== ""
                    ? true
                    : false,
                sellAmount: data.inputs.assets[recordIndex].assetSale!.amount,
                sellDate: data.inputs.assets[recordIndex].assetSale!.startYear,
                rented:
                  data.inputs.assets[recordIndex].assetIncome!.label !== ""
                    ? true
                    : false,
                rentAmount: data.inputs.assets[recordIndex].assetIncome!.amount,
                rentGrowth: data.inputs.assets[recordIndex].assetIncome!.growth,
                rentStart:
                  data.inputs.assets[recordIndex].assetIncome!.startYear,
                assetOnLoan:
                  data.inputs.assets[recordIndex].loan?.liability.label !== ""
                    ? true
                    : false,
                loanAmount:
                  data.inputs.assets[recordIndex].loan?.liability.balance!,
                loanPayment:
                  data.inputs.assets[recordIndex].loan?.loanPayment.amount!,
                loanStartYear:
                  data.inputs.assets[recordIndex].loan?.liability.startYear!,
                loanEndYear:
                  data.inputs.assets[recordIndex].loan?.liability.endYear!,
                assetLoanAmount:
                  data.inputs.assets[recordIndex].loan?.liability.balance!,
              });

              console.log(record);

              setSelectedAssetIndex(recordIndex);
              setEditModalVisible(true);
            }}
          >
            Edit
          </a>
          {"      "}
          <a
            onClick={() => {
              setSelectedAssetIndex(recordIndex);
              setDeleteModalVisible(true);
            }}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  const liabilityColums = [
    { title: "Name", dataIndex: "label", key: "name" },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "amount",
      render: (record: number) =>
        currencySymbol + numberFormat(record, 0, ".", ","),
    },
    {
      title: "Growth",
      dataIndex: "growth",
      key: "growth",
      render: (record: number) => numberFormat(record * 100, 2, ".", ",") + "%",
    },
    { title: "Start Year", dataIndex: "startYear", key: "startYear" },
    { title: "End Year", dataIndex: "endYear", key: "endYear" },
    { title: "Age", dataIndex: "age", key: "age" },
  ];

  const tableData = data.inputs.assets.map((asset) => asset.asset);

  return (
    <Layout>
      {/* <button
        onClick={() => {
          const rate = calcRate(
            2040 - 2021,
            -8500,
            100000 * (1 + 0.025) ** (2021 - 2021)
          );

          console.log(rate * 100);
        }}
      >
        Calc Rate
      </button> */}
      <Card
        bordered={false}
        title="Non-Liquid Assets"
        extra={
          <Button type="primary" onClick={() => setNewModalVisible(true)}>
            Add Asset
          </Button>
        }
      >
        <Table
          rowKey={(record, i) => {
            return i!;
          }}
          columns={columns}
          expandable={{
            expandedRowRender: (record, recordIndex) => (
              <div>
                <Row>
                  <Col span={24}>
                    <Card bordered={false} title="Income">
                      <Table
                        pagination={false}
                        columns={[
                          {
                            title: "Name",
                            dataIndex: "label",
                            key: recordIndex + "AssetInflowName",
                          },
                          {
                            title: "Amount",
                            dataIndex: "amount",
                            key: recordIndex + "assetinflowamount",
                            render: (record) =>
                              currencySymbol +
                              numberFormat(record, 0, ".", ","),
                          },
                          {
                            title: "Growth",
                            dataIndex: "growth",
                            key: recordIndex + "assetinflowgrowth",
                            render: (record) =>
                              numberFormat(record * 100, 2, ".", ",") + "%",
                          },
                          {
                            title: "Start Year",
                            dataIndex: "startYear",
                            key: recordIndex + "assetInflowStartYear",
                          },
                          {
                            title: "End Year",
                            dataIndex: "endYear",
                            key: recordIndex + "assetInflowendYear",
                          },
                        ]}
                        dataSource={[
                          {
                            key: "1",
                            amount:
                              data.inputs.assets[recordIndex].assetIncome
                                .amount,
                            growth:
                              data.inputs.assets[recordIndex].assetIncome
                                .growth,
                            label:
                              data.inputs.assets[recordIndex].assetIncome.label,
                            startYear:
                              data.inputs.assets[recordIndex].assetIncome
                                .startYear,
                            endYear:
                              data.inputs.assets[recordIndex].assetIncome
                                .endYear,
                          },
                          {
                            key: "2",
                            amount:
                              data.inputs.assets[recordIndex].assetSale.amount,
                            growth:
                              data.inputs.assets[recordIndex].assetSale.growth,
                            label:
                              data.inputs.assets[recordIndex].assetSale.label,
                            startYear:
                              data.inputs.assets[recordIndex].assetSale
                                .startYear,
                            endYear:
                              data.inputs.assets[recordIndex].assetSale.endYear,
                          },
                        ]}
                      ></Table>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Card bordered={false} title="Liability">
                      <Table
                        rowKey={(liabilityRecord) => {
                          return liabilityRecord.key!;
                        }}
                        onRow={(r, liabilityIndex: any) => {
                          return {
                            onClick: () => {},
                          };
                        }}
                        columns={liabilityColums}
                        expandable={{
                          expandedRowRender: (
                            liabilityRecord,
                            liabilityRecordIndex
                          ) => (
                            <>
                              <Row>
                                <Col span={24}>
                                  <Card bordered={false} title="Expenditures">
                                    <Table
                                      pagination={false}
                                      columns={[
                                        {
                                          title: "Name",
                                          dataIndex: "label",
                                          key: recordIndex + "AssetInflowName",
                                        },
                                        {
                                          title: "Amount",
                                          dataIndex: "amount",
                                          key:
                                            recordIndex + "assetinflowamount",
                                          render: (record) =>
                                            currencySymbol +
                                            numberFormat(record, 0, ".", ","),
                                        },
                                        {
                                          title: "Growth",
                                          dataIndex: "growth",
                                          key:
                                            recordIndex + "assetinflowgrowth",
                                          render: (record) =>
                                            numberFormat(
                                              record * 100,
                                              2,
                                              ".",
                                              ","
                                            ) + "%",
                                        },
                                        {
                                          title: "Start Year",
                                          dataIndex: "startYear",
                                          key:
                                            recordIndex +
                                            "assetInflowStartYear",
                                        },
                                        {
                                          title: "End Year",
                                          dataIndex: "endYear",
                                          key:
                                            recordIndex + "assetInflowendYear",
                                        },
                                        {
                                          title: "Pay all period",
                                          dataIndex: "payAllPeriod",
                                          key:
                                            recordIndex +
                                            "assetoutflowPayallperiod",
                                        },
                                      ]}
                                      dataSource={[
                                        {
                                          key: "1",
                                          amount:
                                            data.inputs.assets[recordIndex].loan
                                              .loanPayment.amount,
                                          label:
                                            data.inputs.assets[recordIndex].loan
                                              .loanPayment.label,
                                          growth:
                                            data.inputs.assets[recordIndex].loan
                                              .loanPayment.growth,
                                          startYear:
                                            data.inputs.assets[recordIndex].loan
                                              .loanPayment.startYear,
                                          endYear:
                                            data.inputs.assets[recordIndex].loan
                                              .loanPayment.endYear,
                                          payAllPeriod:
                                            data.inputs.assets[recordIndex].loan
                                              .loanPayment.payAllPeriod,
                                        },
                                      ]}
                                    ></Table>
                                  </Card>
                                </Col>
                              </Row>
                            </>
                          ),
                          rowExpandable: (record) =>
                            record.label !== "Not Expandable",
                        }}
                        dataSource={[
                          {
                            key: "1",
                            balance:
                              data.inputs.assets[recordIndex].loan.liability
                                .balance,
                            label:
                              data.inputs.assets[recordIndex].loan.liability
                                .label,
                            growth:
                              data.inputs.assets[recordIndex].loan.liability
                                .rate,
                            startYear:
                              data.inputs.assets[recordIndex].loan.liability
                                .startYear,
                            endYear:
                              data.inputs.assets[recordIndex].loan.liability
                                .endYear,
                          },
                        ]}
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            ),
            rowExpandable: (record) => record.label !== "Not Expandable",
          }}
          dataSource={tableData}
        />
        ,
      </Card>

      {/* New Asset Modal */}
      <Modal
        title={"Add Asset"}
        visible={newModalVisible}
        okText={"Save"}
        onOk={async () => {
          const newConstructedAsset: CreateAssetInputDto = {
            asset: {
              currentValue: newAssetObject.amount,
              endYear: newAssetObject.endYear,
              growth: newAssetObject.growth,
              initialValue: 0,
              label: newAssetObject.name,
              startYear: newAssetObject.startYear,
            },
            label: newAssetObject.name,
            assetIncome: {
              amount: newAssetObject.rented ? newAssetObject.rentAmount : 0,
              endYear: newAssetObject.endYear,
              growth: 0,
              label: "Income - " + newAssetObject.name,
              startYear: newAssetObject.rentStart,
            },
            assetSale: {
              amount: newAssetObject.sellInFuture
                ? newAssetObject.sellAmount
                : 0,
              endYear: newAssetObject.sellDate,
              growth: newAssetObject.growth,
              label: "Sale - " + newAssetObject.name,
              startYear: newAssetObject.sellDate,
            },
            loan: {
              liability: {
                balance: newAssetObject.assetOnLoan
                  ? newAssetObject.loanAmount
                  : 0,
                endYear: newAssetObject.loanEndYear,
                interestOnly: false,
                label: "Loan - " + newAssetObject.name,
                rate: newAssetObject.growth,
                startYear: newAssetObject.loanStartYear,
                years: 9999,
              },
              loanPayment: {
                amount: newAssetObject.assetOnLoan
                  ? newAssetObject.loanPayment
                  : 0,
                endYear: newAssetObject.loanEndYear,
                growth: 0,
                label: "Loan Payment - " + newAssetObject.name,
                startYear: newAssetObject.loanStartYear,
                payAllPeriod: newAssetObject.sellDate,
              },
            },
          };

          const currentYear = new Date().getFullYear();
          let pv = calcLoanPv(
            newAssetObject.loanAmount,
            newAssetObject.growth,
            newAssetObject.startYear
          );

          newConstructedAsset.loan!.loanPayment.growth = Math.abs(
            calcRate(
              newAssetObject.loanStartYear,
              newAssetObject.loanEndYear,
              -newAssetObject.loanPayment,
              pv
            )
          );

          assetObjectForm.validateFields();

          await dispatch(
            createInputsAssetAction(data._id!, newConstructedAsset)
          );

          assetObjectForm.resetFields();

          console.log(newConstructedAsset);

          setNewModalVisible(false);
        }}
        onCancel={() => {
          setNewModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            form={assetObjectForm}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{}}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name of Outflow" }]}
                >
                  <Input
                    value={newAssetObject.name}
                    onChange={(e) => {
                      setNewAssetObject({
                        ...newAssetObject,
                        name: e.target.value,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="amount"
                  label="Market Value"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${newAssetObject.amount}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewAssetObject({
                        ...newAssetObject,
                        amount: +e.target.value.replace(/\$\s?|(,*)/g, ""),
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="growth"
                  label="Growth"
                  rules={[
                    { required: true, message: "Inflation rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${newAssetObject.growth * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewAssetObject({
                        ...newAssetObject,
                        growth:
                          parseFloat(e.target.value.replace("%", "")) / 100,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="startYear"
                  label="Start Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="startYear"
                    defaultValue={moment(`${newAssetObject.startYear}`, "YYYY")}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewAssetObject({
                        ...newAssetObject,
                        startYear: +dateString,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="endYear"
                  label="End Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(`${newAssetObject.startYear}`, "YYYY")}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewAssetObject({
                        ...newAssetObject,
                        endYear: +dateString,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item name="onRent" label="On Rent?">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(e) => {
                      setNewAssetObject({ ...newAssetObject, rented: e });
                    }}
                  />
                </Form.Item>

                {newAssetObject.rented && (
                  <Form.Item
                    name="rentAmount"
                    label="Rent Amount"
                    rules={[{ required: true, message: "Amount of Outflow" }]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      value={`${newAssetObject.rentAmount}`}
                      className="custom-input-fields"
                      onBlur={(e) => {
                        setNewAssetObject({
                          ...newAssetObject,
                          rentAmount: +e.target.value.replace(
                            /\$\s?|(,*)/g,
                            ""
                          ),
                        });
                      }}
                    />
                  </Form.Item>
                )}
                {newAssetObject.rented && (
                  <Form.Item
                    name="rentGrowth"
                    label="Rent Growth"
                    rules={[
                      { required: true, message: "Inflation rate is required" },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      precision={2}
                      style={{ width: "100%" }}
                      formatter={(value) => `${value}%`}
                      parser={(value: any) => value!.replace("%", "")}
                      value={`${newAssetObject.rentGrowth * 100}`}
                      className="custom-input-fields"
                      onBlur={(e) => {
                        setNewAssetObject({
                          ...newAssetObject,
                          rentGrowth:
                            parseFloat(e.target.value.replace("%", "")) / 100,
                        });
                      }}
                    />
                  </Form.Item>
                )}
                {newAssetObject.rented && (
                  <Form.Item
                    name="rentYear"
                    label="Rent Start Year"
                    rules={[
                      { required: true, message: "Please select a year" },
                    ]}
                  >
                    <DatePicker
                      picker="year"
                      name="year"
                      defaultValue={moment(
                        `${newAssetObject.rentStart}`,
                        "YYYY"
                      )}
                      style={{ width: "100%" }}
                      onChange={(date, dateString) => {
                        setNewAssetObject({
                          ...newAssetObject,
                          rentStart: +dateString,
                        });
                      }}
                    />
                  </Form.Item>
                )}

                <Form.Item name="sellProperty" label="Sell property">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(e) => {
                      setNewAssetObject({ ...newAssetObject, sellInFuture: e });
                    }}
                  />
                </Form.Item>

                {newAssetObject.sellInFuture && (
                  <Form.Item
                    name="sellAmount"
                    label="Selling Amount"
                    rules={[{ required: true, message: "Amount of Outflow" }]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      value={`${newAssetObject.sellAmount}`}
                      className="custom-input-fields"
                      onBlur={(e) => {
                        setNewAssetObject({
                          ...newAssetObject,
                          sellAmount: +e.target.value.replace(
                            /\$\s?|(,*)/g,
                            ""
                          ),
                        });
                      }}
                    />
                  </Form.Item>
                )}
                {newAssetObject.sellInFuture && (
                  <Form.Item
                    name="sellYear"
                    label="Sell Year"
                    rules={[
                      { required: true, message: "Please select a year" },
                    ]}
                  >
                    <DatePicker
                      picker="year"
                      name="year"
                      defaultValue={moment(
                        `${newAssetObject.sellDate}`,
                        "YYYY"
                      )}
                      style={{ width: "100%" }}
                      onChange={(date, dateString) => {
                        setNewAssetObject({
                          ...newAssetObject,
                          sellDate: +dateString,
                        });
                      }}
                    />
                  </Form.Item>
                )}

                <Form.Item name="onLoan" label="On Loan?">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(e) => {
                      setNewAssetObject({ ...newAssetObject, assetOnLoan: e });
                    }}
                  />
                </Form.Item>

                {newAssetObject.assetOnLoan && (
                  <Form.Item
                    name="loanAmount"
                    label="Loan Balance"
                    rules={[{ required: true, message: "Amount of Outflow" }]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      value={`${newAssetObject.loanAmount}`}
                      className="custom-input-fields"
                      onBlur={(e) => {
                        setNewAssetObject({
                          ...newAssetObject,
                          loanAmount: +e.target.value.replace(
                            /\$\s?|(,*)/g,
                            ""
                          ),
                        });
                      }}
                    />
                  </Form.Item>
                )}

                {newAssetObject.assetOnLoan && (
                  <Form.Item
                    name="loanPayment"
                    label="Annual Payment"
                    rules={[{ required: true, message: "Amount of Outflow" }]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      value={`${newAssetObject.loanPayment}`}
                      className="custom-input-fields"
                      onBlur={(e) => {
                        setNewAssetObject({
                          ...newAssetObject,
                          loanPayment: +e.target.value.replace(
                            /\$\s?|(,*)/g,
                            ""
                          ),
                        });
                      }}
                    />
                  </Form.Item>
                )}

                {newAssetObject.assetOnLoan && (
                  <Form.Item
                    name="loanStartYear"
                    label="Loan Start Year"
                    rules={[
                      { required: true, message: "Please select a year" },
                    ]}
                  >
                    <DatePicker
                      picker="year"
                      name="year"
                      defaultValue={moment(
                        `${newAssetObject.loanStartYear}`,
                        "YYYY"
                      )}
                      style={{ width: "100%" }}
                      onChange={(date, dateString) => {
                        setNewAssetObject({
                          ...newAssetObject,
                          loanStartYear: +dateString,
                        });
                      }}
                    />
                  </Form.Item>
                )}
                {newAssetObject.assetOnLoan && (
                  <Form.Item
                    name="loanEndYear"
                    label="Loan End Year"
                    rules={[
                      { required: true, message: "Please select a year" },
                    ]}
                  >
                    <DatePicker
                      picker="year"
                      name="year"
                      defaultValue={moment(
                        `${newAssetObject.loanEndYear}`,
                        "YYYY"
                      )}
                      style={{ width: "100%" }}
                      onChange={(date, dateString) => {
                        setNewAssetObject({
                          ...newAssetObject,
                          loanEndYear: +dateString,
                        });
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>

      {/* Edit Asset Modal */}
      <Modal
        title={"Edit Asset"}
        visible={editModalVisible}
        okText={"Save"}
        onOk={async () => {
          dispatch(setLoadingAction(true));

          const newConstructedAsset: CreateAssetInputDto = {
            asset: {
              currentValue: editAssetObject.amount,
              endYear: editAssetObject.endYear,
              growth: editAssetObject.growth,
              initialValue: 0,
              label: editAssetObject.name,
              startYear: editAssetObject.startYear,
            },
            label: editAssetObject.name,
            assetIncome: {
              amount: editAssetObject.rented ? editAssetObject.rentAmount : 0,
              endYear: editAssetObject.rentStart,
              growth: 0,
              label: "Income - " + editAssetObject.name,
              startYear: editAssetObject.sellDate,
            },
            assetSale: {
              amount: editAssetObject.sellInFuture
                ? editAssetObject.sellAmount
                : 0,
              endYear: editAssetObject.sellDate,
              growth: editAssetObject.growth,
              label: "Sale - " + editAssetObject.name,
              startYear: editAssetObject.sellDate,
            },
            loan: {
              liability: {
                balance: editAssetObject.assetOnLoan
                  ? editAssetObject.loanAmount
                  : 0,
                endYear: editAssetObject.loanEndYear,
                interestOnly: false,
                label: "Loan - " + editAssetObject.name,
                rate: editAssetObject.growth,
                startYear: editAssetObject.loanStartYear,
                years: 9999,
              },
              loanPayment: {
                amount: editAssetObject.assetOnLoan
                  ? editAssetObject.loanPayment
                  : 0,
                endYear: editAssetObject.loanEndYear,
                growth: 0,
                label: "Loan Payment - " + editAssetObject.name,
                startYear: editAssetObject.loanStartYear,
                payAllPeriod: editAssetObject.sellDate,
              },
            },
          };

          const currentYear = new Date().getFullYear();
          let nper = editAssetObject.endYear - editAssetObject.startYear;
          let pmt = -editAssetObject.loanPayment;
          let pv =
            editAssetObject.loanAmount *
            (1 + editAssetObject.growth) **
              (editAssetObject.startYear - currentYear);
          newConstructedAsset.loan!.loanPayment.growth = Math.abs(
            formulajs.RATE(nper, pmt, pv)
          );

          formulajs.PV();

          assetObjectForm.validateFields();
          assetObjectForm.resetFields();

          await dispatch(
            updateInputsAction(
              data._id!,
              data.inputs.assets[selectedAssetIndex]._id,
              newConstructedAsset
            )
          );

          // console.log(newConstructedAsset);

          setEditModalVisible(false);

          // setNewModalVisible(false);
        }}
        onCancel={() => {
          setEditModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            form={assetObjectForm}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{}}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Name"
                  rules={[{ required: true, message: "Name of Outflow" }]}
                >
                  <Input
                    value={editAssetObject.name}
                    onChange={(e) => {
                      setEditAssetObject({
                        ...editAssetObject,
                        name: e.target.value,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Amount"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${editAssetObject.amount}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setEditAssetObject({
                        ...editAssetObject,
                        amount: +e.target.value.replace(/\$\s?|(,*)/g, ""),
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Growth"
                  rules={[
                    { required: true, message: "Inflation rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${editAssetObject.growth * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setEditAssetObject({
                        ...editAssetObject,
                        growth:
                          parseFloat(e.target.value.replace("%", "")) / 100,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Start Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${editAssetObject.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setEditAssetObject({
                        ...editAssetObject,
                        startYear: +dateString,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="End Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${editAssetObject.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setEditAssetObject({
                        ...editAssetObject,
                        endYear: +dateString,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item name="onRent" label="On Rent?">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(e) => {
                      setEditAssetObject({ ...editAssetObject, rented: e });
                    }}
                  />
                </Form.Item>

                {editAssetObject.rented && (
                  <Form.Item
                    label="Rent Amount"
                    rules={[{ required: true, message: "Amount of Outflow" }]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      value={`${editAssetObject.rentAmount}`}
                      className="custom-input-fields"
                      onBlur={(e) => {
                        setEditAssetObject({
                          ...editAssetObject,
                          rentAmount: +e.target.value.replace(
                            /\$\s?|(,*)/g,
                            ""
                          ),
                        });
                      }}
                    />
                  </Form.Item>
                )}
                {editAssetObject.rented && (
                  <Form.Item
                    label="Rent Growth"
                    rules={[
                      { required: true, message: "Inflation rate is required" },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      precision={2}
                      style={{ width: "100%" }}
                      formatter={(value) => `${value}%`}
                      parser={(value: any) => value!.replace("%", "")}
                      value={`${editAssetObject.rentGrowth * 100}`}
                      className="custom-input-fields"
                      onBlur={(e) => {
                        setEditAssetObject({
                          ...editAssetObject,
                          rentGrowth:
                            parseFloat(e.target.value.replace("%", "")) / 100,
                        });
                      }}
                    />
                  </Form.Item>
                )}
                {editAssetObject.rented && (
                  <Form.Item
                    label="Rent Start Year"
                    rules={[
                      { required: true, message: "Please select a year" },
                    ]}
                  >
                    <DatePicker
                      picker="year"
                      name="year"
                      defaultValue={moment(
                        `${editAssetObject.rentStart}`,
                        "YYYY"
                      )}
                      style={{ width: "100%" }}
                      onChange={(date, dateString) => {
                        setEditAssetObject({
                          ...editAssetObject,
                          rentStart: +dateString,
                        });
                      }}
                    />
                  </Form.Item>
                )}

                <Form.Item name="sellProperty" label="Sell property">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(e) => {
                      setEditAssetObject({
                        ...editAssetObject,
                        sellInFuture: e,
                      });
                    }}
                  />
                </Form.Item>

                {editAssetObject.sellInFuture && (
                  <Form.Item
                    label="Selling Amount"
                    rules={[{ required: true, message: "Amount of Outflow" }]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      value={`${editAssetObject.sellAmount}`}
                      className="custom-input-fields"
                      onBlur={(e) => {
                        setEditAssetObject({
                          ...editAssetObject,
                          sellAmount: +e.target.value.replace(
                            /\$\s?|(,*)/g,
                            ""
                          ),
                        });
                      }}
                    />
                  </Form.Item>
                )}
                {editAssetObject.sellInFuture && (
                  <Form.Item
                    label="Sell Year"
                    rules={[
                      { required: true, message: "Please select a year" },
                    ]}
                  >
                    <DatePicker
                      picker="year"
                      name="year"
                      defaultValue={moment(
                        `${editAssetObject.sellDate}`,
                        "YYYY"
                      )}
                      style={{ width: "100%" }}
                      onChange={(date, dateString) => {
                        setEditAssetObject({
                          ...editAssetObject,
                          sellDate: +dateString,
                        });
                      }}
                    />
                  </Form.Item>
                )}

                <Form.Item name="onLoan" label="On Loan?">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(e) => {
                      setEditAssetObject({
                        ...editAssetObject,
                        assetOnLoan: e,
                      });
                    }}
                  />
                </Form.Item>

                {editAssetObject.assetOnLoan && (
                  <Form.Item
                    label="Loan Balance"
                    rules={[{ required: true, message: "Amount of Outflow" }]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      value={`${editAssetObject.loanAmount}`}
                      className="custom-input-fields"
                      onBlur={(e) => {
                        setEditAssetObject({
                          ...editAssetObject,
                          loanAmount: +e.target.value.replace(
                            /\$\s?|(,*)/g,
                            ""
                          ),
                        });
                      }}
                    />
                  </Form.Item>
                )}

                {editAssetObject.assetOnLoan && (
                  <Form.Item
                    label="Annual Payment"
                    rules={[{ required: true, message: "Amount of Outflow" }]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      value={`${editAssetObject.loanPayment}`}
                      className="custom-input-fields"
                      onBlur={(e) => {
                        setEditAssetObject({
                          ...editAssetObject,
                          loanPayment: +e.target.value.replace(
                            /\$\s?|(,*)/g,
                            ""
                          ),
                        });
                      }}
                    />
                  </Form.Item>
                )}

                {editAssetObject.assetOnLoan && (
                  <Form.Item
                    label="Loan Start Year"
                    rules={[
                      { required: true, message: "Please select a year" },
                    ]}
                  >
                    <DatePicker
                      picker="year"
                      name="year"
                      defaultValue={moment(
                        `${editAssetObject.loanStartYear}`,
                        "YYYY"
                      )}
                      style={{ width: "100%" }}
                      onChange={(date, dateString) => {
                        setEditAssetObject({
                          ...editAssetObject,
                          loanStartYear: +dateString,
                        });
                      }}
                    />
                  </Form.Item>
                )}
                {editAssetObject.assetOnLoan && (
                  <Form.Item
                    label="Loan End Year"
                    rules={[
                      { required: true, message: "Please select a year" },
                    ]}
                  >
                    <DatePicker
                      picker="year"
                      name="year"
                      defaultValue={moment(
                        `${editAssetObject.loanEndYear}`,
                        "YYYY"
                      )}
                      style={{ width: "100%" }}
                      onChange={(date, dateString) => {
                        setEditAssetObject({
                          ...editAssetObject,
                          loanEndYear: +dateString,
                        });
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>

      {/* Delete Inflow Modal */}
      <Modal
        title="Delete inflow"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={async () => {
          await dispatch(
            deleteInputsAcion(
              data._id!,
              data.inputs.assets[selectedAssetIndex]._id
            )
          );
          setDeleteModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
      >
        Are you sure you want to delete the income?
      </Modal>
    </Layout>
  );
};

export default NonLiquidAssets;
