import { Card, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteHouseholdAction } from "../../../../../redux/Households";
import { RootState } from "../../../../../redux/store";

type ModalProps = {
  householdId: string;
  deleteModalVisible: boolean;
  setDeleteModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteHouseholdModal = ({
  householdId,
  deleteModalVisible,
  setDeleteModalVisible,
}: ModalProps) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.loadingReducer);
  return (
    <Modal
      confirmLoading={loading}
      visible={deleteModalVisible}
      onCancel={() => setDeleteModalVisible(false)}
      onOk={async () => {
        await dispatch(deleteHouseholdAction(householdId));
        setDeleteModalVisible(false);
      }}
      title="Delete Household"
    >
      Are you sure you want to delete the Household?
    </Modal>
  );
};

export default DeleteHouseholdModal;
