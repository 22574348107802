import {
  Button,
  Card,
  Dropdown,
  Menu,
  Row,
  Space,
  Table,
  Form,
  Input,
  InputNumber,
  Col,
  DatePicker,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import IData from "../../../interface/IData";
import { MoreOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";

import { getDataAction } from "../../../redux/Data";
import { setLoadingAction } from "../../../redux/General/Loading";

import {
  CreateIncomeInputDto,
  UpdateIncomeInputDto,
} from "@advicefront/plan-api";
import {
  createInputsIncomeAction,
  deleteInputsAcion,
  updateInputsAction,
} from "../../../redux/Inputs";
import { numberFormat } from "highcharts";
import { currencySymbol } from "../../../components/common/currencySymbol";
import { start } from "repl";

const Income = () => {
  const dispatch = useDispatch();

  const data: IData = useSelector((state: RootStateOrAny) => state.dataReducer);

  const loading: boolean = useSelector(
    (state: RootStateOrAny) => state.loadingReducer
  );

  const [isNewIncomeModalVisible, setIsNewIncomeModalVisible] = useState(false);
  const [inflowEditModalVisible, setInflowEditModalVisible] = useState(false);
  const [isInflowDeleteModalVisible, setIsDeleteInflowModalVisible] =
    useState(false);

  const [newInflow, setNewInflow] = useState<CreateIncomeInputDto>({
    label: "",
    cashIn: {
      label: "",
      amount: 0,
      growth: 0,
      startYear: 2021,
      endYear: 2021,
    },
  });

  const [selectedInflowIndex, setSelectedInflowIndex] = useState(0);

  const [selectedInflow, setSelectedInflow] = useState<UpdateIncomeInputDto>({
    label: "",
    cashIn: {
      label: "",
      amount: 0,
      growth: 0,
      startYear: 2021,
      endYear: 2021,
    },
  });

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const handleSubmit = async () => {
    dispatch(setLoadingAction(true));

    await dispatch(createInputsIncomeAction(data._id!, newInflow));

    await dispatch(getDataAction(data._id!));

    setIsNewIncomeModalVisible(false);
    setNewInflow({
      label: "",
      cashIn: {
        amount: 0,
        endYear: 0,
        growth: 0,
        label: "",
        startYear: 0,
      },
    });
    dispatch(setLoadingAction(false));
  };

  return (
    <Layout>
      <Card
        bordered={false}
        title="Employment/Income"
        extra={
          <Button
            type="primary"
            onClick={() => setIsNewIncomeModalVisible(true)}
          >
            Add Income
          </Button>
        }
      >
        <Table
          rowKey={(record) => {
            return record._id!.toString();
          }}
          pagination={false}
          columns={[
            {
              title: "Name",
              dataIndex: "label",
              key: "label",
            },
            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
              render: (record) =>
                currencySymbol + numberFormat(record, 0, ".", ","),
            },
            {
              title: "Growth",
              dataIndex: "growth",
              key: "growth",
              render: (record) => numberFormat(record * 100, 2) + "%",
            },
            {
              title: "Start Year",
              dataIndex: "startYear",
              key: "startYear",
            },
            {
              title: "End Year",
              dataIndex: "endYear",
              key: "endYear",
            },
            {
              title: "Actions",
              render: (value, record, recordIndex) => (
                <Space size="middle">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="2">
                          <Button
                            type="link"
                            onClick={async (e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              setSelectedInflow(
                                data.inputs.income[recordIndex]
                              );
                              setSelectedInflowIndex(recordIndex);
                              setInflowEditModalVisible(true);
                            }}
                          >
                            Edit
                          </Button>
                        </Menu.Item>
                        <Menu.Item key="3">
                          <Button
                            type="link"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setSelectedInflow(
                                data.inputs.income[recordIndex]
                              );
                              setSelectedInflowIndex(recordIndex);
                              setIsDeleteInflowModalVisible(true);
                            }}
                          >
                            Delete
                          </Button>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button
                      size="small"
                      onClick={async (e) => {
                        e.stopPropagation();
                      }}
                    >
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </Space>
              ),
            },
          ]}
          dataSource={data.inputs.income.map((i) => i.cashIn)}
        />
      </Card>

      {/* New Inflow Modal */}
      <Modal
        title={"Add Income"}
        visible={isNewIncomeModalVisible}
        okText={"Save"}
        onOk={async () => {
          form.submit();
        }}
        onCancel={() => {
          setIsNewIncomeModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            form={form}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{
              amount: 0,
              growth: 0,
              startYear: moment(`${newInflow.cashIn.startYear}`, "YYYY"),
              endYear: moment(`${newInflow.cashIn.startYear}`, "YYYY"),
            }}
            onFinish={() => handleSubmit()}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Income name is required" },
                  ]}
                >
                  <Input
                    value={newInflow.label}
                    onChange={(e) => {
                      setNewInflow({
                        ...newInflow,
                        label: e.target.value,
                        cashIn: {
                          ...newInflow.cashIn,
                          label: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="amount"
                  label="Amount"
                  rules={[
                    { required: true, message: "Income amount required" },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `${currencySymbol}${value}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )
                    }
                    value={`${newInflow.cashIn.amount}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewInflow({
                        ...newInflow,
                        cashIn: {
                          ...newInflow.cashIn,
                          amount: +e.target.value.replace(`/\$\s?|(,*)/g`, ""),
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="growth"
                  label="Growth"
                  rules={[
                    { required: true, message: "Growth rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${newInflow.cashIn.growth * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewInflow({
                        ...newInflow,
                        cashIn: {
                          ...newInflow.cashIn,
                          growth:
                            parseFloat(e.target.value.replace("%", "")) / 100,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="startYear"
                  label="Start Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${newInflow.cashIn.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewInflow({
                        ...newInflow,
                        cashIn: {
                          ...newInflow.cashIn,
                          startYear: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="endYear"
                  label="End Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${newInflow.cashIn.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewInflow({
                        ...newInflow,
                        cashIn: {
                          ...newInflow.cashIn,
                          endYear: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>

      {/* Edit Inflow Modal */}
      <Modal
        title={"Edit Income"}
        visible={inflowEditModalVisible}
        okText={"Save"}
        onOk={async () => {
          const newInput: UpdateIncomeInputDto = selectedInflow;

          dispatch(
            updateInputsAction(
              data._id!,
              data.inputs.income[selectedInflowIndex]._id!,
              newInput
            )
          );

          setSelectedInflow({
            label: "",
            cashIn: {
              amount: 0,
              endYear: 0,
              growth: 0,
              label: "",
              startYear: 0,
            },
          });
          setInflowEditModalVisible(false);
        }}
        onCancel={() => {
          setInflowEditModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            form={editForm}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{}}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Name"
                  rules={[{ required: true, message: "Inflow name required" }]}
                >
                  <Input
                    value={selectedInflow.cashIn?.label}
                    onChange={(e) => {
                      setSelectedInflow({
                        label: e.target.value,
                        ...selectedInflow,
                        cashIn: {
                          ...selectedInflow.cashIn!,
                          label: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Amount"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `${currencySymbol}${value}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )
                    }
                    value={`${selectedInflow.cashIn!.amount}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setSelectedInflow({
                        ...selectedInflow,
                        cashIn: {
                          ...selectedInflow.cashIn!,
                          amount: +e.target.value.replace(/\$\s?|(,*)/g, ""),
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Growth"
                  rules={[
                    { required: true, message: "Inflation rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${selectedInflow.cashIn!.growth * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setSelectedInflow({
                        ...selectedInflow,
                        cashIn: {
                          ...selectedInflow.cashIn!,
                          growth:
                            parseFloat(e.target.value.replace("%", "")) / 100,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Start Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${selectedInflow.cashIn!.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setSelectedInflow({
                        ...selectedInflow,
                        cashIn: {
                          ...selectedInflow.cashIn!,
                          growth: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="End Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${selectedInflow.cashIn!.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setSelectedInflow({
                        ...selectedInflow,
                        cashIn: {
                          ...selectedInflow.cashIn!,
                          endYear: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>

      {/* Delete Inflow Modal */}
      <Modal
        title="Delete inflow"
        visible={isInflowDeleteModalVisible}
        onCancel={() => setIsDeleteInflowModalVisible(false)}
        onOk={async () => {
          dispatch(setLoadingAction(true));
          dispatch(
            deleteInputsAcion(
              data._id!,
              data.inputs.income[selectedInflowIndex]._id!
            )
          );
          setIsDeleteInflowModalVisible(false);
          dispatch(setLoadingAction(false));
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
      >
        Are you sure you want to delete the income?
      </Modal>
    </Layout>
  );
};

export default Income;
