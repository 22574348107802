import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  Table,
  Card,
  Space,
  Button,
  Row,
  Modal,
  Form,
  Input,
  Menu,
  Dropdown,
  DatePicker,
} from "antd";

import { MoreOutlined } from "@ant-design/icons";
import Layout from "antd/lib/layout/layout";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
  addPlanAction,
  deletePlanAction,
  getPlansAction,
  planReducer,
  setActivePlanAction,
  updatePlanAction,
} from "../../redux/Plan";

import IPlan from "../../interface/IPlan";
import { setAlertAction } from "../../redux/General/Alert";
import { useHistory } from "react-router-dom";
import { inputsRoute, planDashboardRoute } from "../../routes/navRoutes";
import { getDataAction } from "../../redux/Data";
import moment from "moment";
import { CreatePlanDto, UpdatePlanDto } from "@advicefront/plan-api";
import { RootState } from "../../redux/store";
import IHouseholds from "../../interface/IHouseholds";

const Plans = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [planArr, setPlanArr] = useState<IPlan[]>([]);
  const plans = useSelector((state: RootState) => state.planReducer);
  const loading = useSelector((state: RootState) => state.loadingReducer);
  const activeHousehold: IHouseholds = useSelector(
    (state: RootState) => state.activeHouseholdReducer
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [planEditModalVisible, setPlanEditModalVisible] = useState(false);
  const [planDeleteModalVisible, setPlanDeleteModalVisible] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [selectedId, setSelectedId] = useState("");

  const [selectedPlan, setSelectedPlan] = useState<UpdatePlanDto>({
    endYear: 2040,
    name: "",
    startYear: 2021,
  });

  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPlanDeleteModalVisible(false);
    setPlanEditModalVisible(false);
  };

  const columns = [
    {
      title: "Plan Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Start Year",
      dataIndex: "startYear",
      key: "startyear",
    },
    {
      title: "End Year",
      dataIndex: "endYear",
      key: "endyear",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: IPlan, rowIndex: any) => (
        <Space size="middle">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="2">
                  <Button
                    type="link"
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setPlanEditModalVisible(true);
                      setSelectedPlanIndex(rowIndex);

                      setSelectedPlan(record);
                    }}
                  >
                    Edit
                  </Button>
                </Menu.Item>
                <Menu.Item key="3">
                  <Button
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedPlanIndex(rowIndex);
                      setPlanDeleteModalVisible(true);
                    }}
                  >
                    Delete
                  </Button>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              size="small"
              onMouseEnter={() => {
                setSelectedId(record._id!);
                setSelectedIndex(rowIndex);
              }}
              onClick={async (e) => {
                e.stopPropagation();
              }}
            >
              <MoreOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const [data, setData] = useState(plans);
  useEffect(() => {
    setData(plans);
  }, [plans]);

  const [planInfo, setPlanInfo] = useState<CreatePlanDto>({
    name: "",
    startYear: 2021,
    endYear: 2040,
    household: activeHousehold._id,
  });

  useEffect(() => {
    console.log(activeHousehold);

    setPlanInfo({
      ...planInfo,
      household: activeHousehold._id,
    });
  }, [activeHousehold]);

  const [form] = Form.useForm();

  return (
    <Layout className="layout" style={{ backgroundColor: "white" }}>
      <Card bordered={false} style={{ margin: "16px" }}>
        <Row justify="end">
          <Button
            type="primary"
            style={{ marginBottom: 16 }}
            onClick={showModal}
            loading={loading}
          >
            Add new plan
          </Button>
        </Row>
        <Table
          loading={loading}
          onRow={(record: IPlan, rowIndex: any) => {
            return {
              style: {
                cursor: "pointer",
              },
              onClick: async () => {
                await dispatch(setActivePlanAction(record));
                await dispatch(getDataAction(record._id!));
                history.push(inputsRoute);
              },
            };
          }}
          columns={columns}
          dataSource={data}
          rowKey={(record: any, rowIndex: any) => {
            return rowIndex;
          }}
          pagination={false}
        />
      </Card>

      {/* New plan modal */}
      <Modal
        title="Add New Plan"
        visible={isModalVisible}
        okButtonProps={{
          loading,
        }}
        onOk={async () => {
          await dispatch(addPlanAction(planInfo));
          setIsModalVisible(false);

          form.resetFields();
        }}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{}}
        >
          <Form.Item
            name="name"
            label="Plan Name"
            rules={[{ required: true, message: "Plan name is required" }]}
          >
            <Input
              name="name"
              onChange={(e) => {
                setPlanInfo({ ...planInfo, name: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item
            name="startYear"
            label="Start Year"
            rules={[{ required: true, message: "Please select a year" }]}
          >
            <DatePicker
              picker="year"
              name="year"
              defaultValue={moment(`${planInfo.startYear}`, "YYYY")}
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setPlanInfo({
                  ...planInfo,
                  startYear: +dateString,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name="endyear"
            label="End Year"
            rules={[{ required: true, message: "Please select a year" }]}
          >
            <DatePicker
              picker="year"
              name="year"
              defaultValue={moment(`${planInfo.endYear}`, "YYYY")}
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setPlanInfo({
                  ...planInfo,
                  endYear: +dateString,
                });
              }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Plan"
        visible={planEditModalVisible}
        okText="Update"
        okButtonProps={{
          loading,
        }}
        onOk={async () => {
          console.log(plans);

          await dispatch(
            updatePlanAction(plans[selectedPlanIndex]._id, {
              name: selectedPlan.name,
              startYear: selectedPlan.startYear,
              endYear: selectedPlan.endYear,
            })
          );
          setPlanEditModalVisible(false);
        }}
        onCancel={handleCancel}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{}}
        >
          <Form.Item
            label="Plan Name"
            rules={[{ required: true, message: "Plan name is required" }]}
          >
            <Input
              value={selectedPlan?.name}
              onChange={(e) => {
                setSelectedPlan({ ...selectedPlan, name: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Start Year"
            rules={[{ required: true, message: "Please select a year" }]}
          >
            <DatePicker
              picker="year"
              name="year"
              value={moment(`${selectedPlan.startYear}`, "YYYY")}
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setSelectedPlan({
                  ...planInfo,
                  startYear: +dateString,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            label="End Year"
            rules={[{ required: true, message: "Please select a year" }]}
          >
            <DatePicker
              picker="year"
              name="year"
              value={moment(`${selectedPlan.endYear}`, "YYYY")}
              style={{ width: "100%" }}
              onChange={(date, dateString) => {
                setSelectedPlan({
                  ...planInfo,
                  endYear: +dateString,
                });
              }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Delete Plan"
        okType={"danger"}
        visible={planDeleteModalVisible}
        okText="Delete"
        okButtonProps={{
          loading,
        }}
        onOk={() => {
          console.log(plans[selectedPlanIndex]._id);

          dispatch(deletePlanAction(plans[selectedPlanIndex]._id));

          setPlanDeleteModalVisible(false);
        }}
        onCancel={() => setPlanDeleteModalVisible(false)}
      >
        <p>
          <strong>Are you sure you want to delete Plan? </strong>
        </p>
        <p>
          <strong>This action cannot be undone! </strong>
        </p>
      </Modal>
    </Layout>
  );
};

export default Plans;
