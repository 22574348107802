import {
  CreateClientPersonDtoLegalGenderEnum,
  CreateHouseholdDto,
  HouseholdsApi,
} from "@advicefront/plan-api";
import IHouseholds from "../../interface/IHouseholds";
import { apiRoute } from "../../routes/apiRoutes";
import { setLoadingAction } from "../General/Loading";

const householdsApi = new HouseholdsApi({
  isJsonMime: () => true,
  basePath: apiRoute,
});

export const getHouseholdsAction = () => async (dispatch: any) => {
  dispatch(setLoadingAction(true));
  const res = await householdsApi.householdsControllerFindAll();

  dispatch({
    type: "GET_HOUSEHOLDS",
    payload: res.data,
  });

  dispatch(setLoadingAction(false));
};

export const createHouseholdAction =
  (newHouseholdObject: CreateHouseholdDto) => async (dispatch: any) => {
    dispatch(setLoadingAction(true));
    const res = await householdsApi.householdsControllerCreate(
      newHouseholdObject
    );

    await dispatch(getHouseholdsAction());
    dispatch(setLoadingAction(false));
  };

export const updateHouseholdAction = () => async (dispatch: any) => {};

export const deleteHouseholdAction =
  (householdId: string) => async (dispatch: any) => {
    dispatch(setLoadingAction(true));
    const res = await householdsApi.householdsControllerRemove(householdId);
    await dispatch(getHouseholdsAction());
    dispatch(setLoadingAction(false));
  };

export const setActiveHouseholdAction =
  (household: IHouseholds) => (dispatch: any) => {
    dispatch({
      type: "SET_ACTIVE_HOUSEHOLD",
      payload: household,
    });
  };

export const householdsReducer = (
  state: CreateHouseholdDto[] = [],
  action: any
) => {
  const { type, payload } = action;

  switch (type) {
    case "GET_HOUSEHOLDS":
      return (state = payload);

    default:
      return state;
  }
};

const initialHouseholdState: IHouseholds = {
  _id: "",
  client: {
    _id: "",
    dateOfBirth: "",
    legalGender: CreateClientPersonDtoLegalGenderEnum.M,
    name: "",
    retirementAge: 0,
  },
  dependents: [],
  partner: {
    _id: "",
    dateOfBirth: "",
    legalGender: CreateClientPersonDtoLegalGenderEnum.F,
    name: "",
    retirementAge: 0,
  },
};

export const activeHouseholdReducer = (
  state: IHouseholds = initialHouseholdState,
  action: any
) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_ACTIVE_HOUSEHOLD":
      return (state = payload);
    default:
      return state;
  }
};
