import { v4 as uuid } from "uuid";

interface IAlert {
  id: string;
  name: string;
  alertType: "Success" | "Danger" | "Warning";
}

export const setAlertAction =
  (name: string, alertType: "Success" | "Danger" | "Warning") =>
  (dispatch: any) => {
    dispatch({
      type: "SET_ALERT",
      payload: {
        id: uuid(),
        name,
        alertType,
      },
    });
  };
export const removeAlertAction = (alertId: string) => (dispatch: any) => {};

export const alertsReducer = (state: IAlert[] = [], action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_ALERT":
      return [...state, payload];

    case "REMOVE_ALERT":
      const newState = state.filter((s) => {
        return s.id !== payload;
      });
      return (state = newState);
    default:
      return state;
  }
};
