import { CreateDependentPersonDto } from "@advicefront/plan-api";
import { Card, Table } from "antd";
import moment from "moment";

type CardProps = {
  dependents: CreateDependentPersonDto[];
  title: string;
};

const HouseholdDependantsTable = ({ dependents, title }: CardProps) => {
  return (
    <Card bordered={false} size="small" title={title}>
      <Table
        size="small"
        bordered={false}
        pagination={false}
        dataSource={dependents}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Date of birth",
            dataIndex: "dateOfBirth",
            render: (value) => moment(value).format("DD/MM/YYYY"),
            key: "dateOfBirth",
          },
          {
            title: "Dependant untill",
            dataIndex: "dependentUntil",
            key: "dependentUntil",
          },

          {
            title: "Legal gender",
            dataIndex: "legalGender",
            render: (value) => (value === "M" ? "Male" : "Female"),
            key: "legalGender",
          },
        ]}
      />
    </Card>
  );
};

export default HouseholdDependantsTable;
