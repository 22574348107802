import { Layout } from "antd";
import HouseholdsTable from "./components/household/table/HouseholdTable";

const Households = () => {
  return (
    <Layout>
      <HouseholdsTable />
    </Layout>
  );
};

export default Households;
