export const loginFormRoute = "/login";
export const dashboardRoute = "/dashboard";
export const planDashboardRoute = "/dashboard/plandashboard";
export const inflowsRoute = "/dashboard/plandashboard/inflows";
export const outflowsRoute = "/dashboard/plandashboard/outflows";
export const assetsRoute = "/dashboard/plandashboard/assets";
export const liabilityRoute = "/dashboard/plandashboard/liability";
export const inputsRoute = "/dashboard/plandashboard/inputs";
export const forecastRoute = "/dashboard/plandashboard/forecast";
export const householdRoute = "/dashboard/households";
export const plansRoute = "/dashboard/households/plans";
