import { IAsset } from "../../../interface/IAsset";
import { ILiability } from "../../../interface/ILiability";

export function calcPresentValue(
  asset: IAsset | ILiability,
  currentYear: number
) {
  const pv =
    asset.amount / (1 + asset.growth) ** (asset.startYear - currentYear);
  return pv;
}

export function calcFutureValue(
  rate: number,
  nper: number,
  pmt: number,
  pv: number,
  type: number
): number {
  var pow = Math.pow(1 + rate, nper),
    fv;
  if (rate) {
    fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
  } else {
    fv = -1 * (pv + pmt * nper);
  }
  return fv;
}
