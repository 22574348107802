import { CreateClientPersonDto } from "@advicefront/plan-api";
import { Card, List } from "antd";
import moment from "moment";
import { Fragment } from "react";

type CardProps = {
  person: CreateClientPersonDto;
  title: String;
};

const HouseholdTableCard = ({ person, title }: CardProps) => {
  return (
    <Fragment>
      <Card bordered={false} size="small" title={title}>
        <List split={false} size="small">
          <List.Item> Name : {person.name}</List.Item>
          <List.Item>
            Date of birth:
            {moment(person.dateOfBirth).format("DD/MM/YYYY")}
          </List.Item>
          <List.Item>Retirement age: {person.retirementAge}</List.Item>
          <List.Item>
            Legal gender: {person.legalGender === "M" ? "Male" : "Female"}
          </List.Item>
        </List>
      </Card>
    </Fragment>
  );
};

export default HouseholdTableCard;
