const calcLoanPv = (
  loanAmount: number,
  loanRate: number,
  loanStartYear: number
) => {
  const currentYear = new Date().getFullYear();
  const pv = loanAmount * (1 + loanRate) ** (loanStartYear - currentYear);

  return pv;
};

export default calcLoanPv;
