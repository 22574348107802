const calcRate = (
  startYear: number,
  endYear: number,
  payment: number,
  present: number,
  future?: number,
  type?: number,
  guess?: number
) => {
  guess = guess === undefined ? 0.01 : guess;
  future = future === undefined ? 0 : future;
  type = type === undefined ? 0 : type;

  var epsMax = 1e-10;
  var iterMax = 20;
  var rate = guess;
  const period = endYear - startYear;

  type = type ? 1 : 0;
  for (var i = 0; i < iterMax; i++) {
    var y, f;
    if (Math.abs(rate) < epsMax) {
      y =
        present * (1 + period * rate) +
        payment * (1 + rate * type) * period +
        future;
    } else {
      f = Math.pow(1 + rate, period);
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
    if (Math.abs(y) < epsMax) {
      return rate;
    }
    var dy;
    if (Math.abs(rate) < epsMax) {
      dy = present * period + payment * type * period;
    } else {
      f = Math.pow(1 + rate, period);
      var df = period * Math.pow(1 + rate, period - 1);
      dy =
        present * df +
        payment * (1 / rate + type) * df +
        payment * (-1 / (rate * rate)) * (f - 1);
    }
    rate -= y / dy;
  }

  return rate;
};

export default calcRate;
