import "antd/dist/antd.css";

import { Route, Switch } from "react-router-dom";

import { Layout } from "antd";
import Login from "./pages/Auth/Login";
import {
  dashboardRoute,
  forecastRoute,
  inputsRoute,
  plansRoute,
} from "./routes/navRoutes";
import Dashboard from "./pages/Dashboard";

import SideNav from "./components/common/navs/SideNav";
import HeadNav from "./components/common/navs/HeadNav";

import Forecast from "./pages/Dashboard/Forecast";

import Inputs from "./pages/Dashboard/Inputs";
import { Header } from "antd/lib/layout/layout";
import Households from "./pages/household";

const { Content, Sider } = Layout;

const App = () => {
  return (
    <Layout>
      <Header style={{ backgroundColor: "white" }}>
        <Route path="/dashboard/" component={HeadNav} />
      </Header>

      <Route exact path="/" component={Login} />
      <Layout>
        <Sider style={{ backgroundColor: "white" }}>
          <Route path="/dashboard/plandashboard" component={SideNav} />
        </Sider>
        <Content>
          <Switch>
            <Route exact path={plansRoute} component={Dashboard} />
            <Route exact path={dashboardRoute} component={Households} />

            <Route exact path={inputsRoute} component={Inputs} />
            <Route exact path={forecastRoute} component={Forecast} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
