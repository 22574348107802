import { createStore, applyMiddleware } from "redux";
import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { planReducer, activePlanReducer } from "./Plan";
import { loadingReducer } from "./General/Loading";
import { alertsReducer } from "./General/Alert";
import { dataReducer, forecastReducer } from "./Data";
import { activeHouseholdReducer, householdsReducer } from "./Households";

const rootReducers = combineReducers({
  planReducer,
  loadingReducer,
  alertsReducer,
  activePlanReducer,
  dataReducer,
  forecastReducer,
  householdsReducer,
  activeHouseholdReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const initialState: any = {};
const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
export default store;
