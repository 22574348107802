import { Dispatch } from "redux";
import { apiRoute } from "../../routes/apiRoutes";
import { getDataAction } from "../Data";
import { setLoadingAction } from "../General/Loading";

import {
  InputsApi,
  CreateIncomeInputDto,
  CreateExpenseInputDto,
  CreateBankAccountInputDto,
  CreateAssetInputDto,
  CreateLoanInputDto,
  UpdateAssetInputDto,
  UpdateBankAccountInputDto,
  UpdateExpenseInputDto,
  UpdateIncomeInputDto,
  UpdateLoanInputDto,
} from "@advicefront/plan-api";
import { StringGradients } from "antd/lib/progress/progress";

const inputsApi = new InputsApi({
  isJsonMime: () => true,
  basePath: apiRoute,
});

const types = {
  getInputsType: "GET_INPUTS",
  setForecastType: "SET_FORECAST",
};

export const createInputsIncomeAction =
  (planId: string, income: CreateIncomeInputDto) => async (dispatch: any) => {
    dispatch(setLoadingAction(true));

    try {
      const res = await inputsApi.inputsControllerCreateIncome(planId, income);

      dispatch(getDataAction(planId));
    } catch (err: any) {
      console.log(err.response.data);
    }

    dispatch(setLoadingAction(false));
  };

export const createInputsExpenseAction =
  (planId: string, expense: CreateExpenseInputDto) => async (dispatch: any) => {
    dispatch(setLoadingAction(true));

    const res = await inputsApi.inputsControllerCreateExpense(planId, expense);

    dispatch(getDataAction(planId));

    dispatch(setLoadingAction(false));
  };

export const createInputsBankAccountAction =
  (planId: string, bankAccount: CreateBankAccountInputDto) =>
  async (dispatch: any) => {
    dispatch(setLoadingAction(true));

    const res = await inputsApi.inputsControllerCreateBankAccount(
      planId,
      bankAccount
    );

    dispatch(getDataAction(planId));

    dispatch(setLoadingAction(false));
  };

export const createInputsAssetAction =
  (planId: string, asset: CreateAssetInputDto) => async (dispatch: any) => {
    dispatch(setLoadingAction(true));

    const res = await inputsApi.inputsControllerCreateAsset(planId, asset);

    dispatch(getDataAction(planId));

    dispatch(setLoadingAction(false));
  };

export const createInputsLoanAction =
  (planId: string, loan: CreateLoanInputDto) => async (dispatch: any) => {
    dispatch(setLoadingAction(true));

    const res = await inputsApi.inputsControllerCreateLoan(planId, loan);

    console.log(res);

    dispatch(getDataAction(planId));

    dispatch(setLoadingAction(false));
  };

export const updateInputsAction =
  (
    planId: string,
    inputId: string,
    input:
      | UpdateAssetInputDto
      | UpdateBankAccountInputDto
      | UpdateExpenseInputDto
      | UpdateIncomeInputDto
      | UpdateLoanInputDto
  ) =>
  async (dispatch: any) => {
    dispatch(setLoadingAction(true));
    const res = await inputsApi.inputsControllerUpdateInput(
      planId,
      inputId,
      input
    );

    dispatch(getDataAction(planId));
    dispatch(setLoadingAction(false));
  };

export const deleteInputsAcion =
  (planId: string, inputId: string) => async (dispatch: any) => {
    dispatch(setLoadingAction(true));
    const res = await inputsApi.inputsControllerRemove(planId, inputId);

    dispatch(getDataAction(planId));
    dispatch(setLoadingAction(false));
  };
