import {
  Button,
  Card,
  Dropdown,
  Menu,
  Row,
  Space,
  Table,
  Form,
  Input,
  InputNumber,
  Col,
  DatePicker,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import IData from "../../../interface/IData";
import { MoreOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";

import { getDataAction } from "../../../redux/Data";
import { setLoadingAction } from "../../../redux/General/Loading";

import {
  CreateExpenseInputDto,
  UpdateExpenseInputDto,
} from "@advicefront/plan-api";
import {
  createInputsExpenseAction,
  deleteInputsAcion,
  updateInputsAction,
} from "../../../redux/Inputs";
import { currencySymbol } from "../../../components/common/currencySymbol";
import { numberFormat } from "highcharts";

const Expense = () => {
  const dispatch = useDispatch();

  const data: IData = useSelector((state: RootStateOrAny) => state.dataReducer);

  const loading: boolean = useSelector(
    (state: RootStateOrAny) => state.loadingReducer
  );

  const [isNewExpenseModalVisible, setIsNewExpenseModalVisible] =
    useState(false);
  const [outflowEditModalVisible, setOutflowEditModalVisible] = useState(false);
  const [isOutflowDeleteModalVisible, setIsDeleteOutflowModalVisible] =
    useState(false);

  const [newOutflow, setNewOutflow] = useState<CreateExpenseInputDto>({
    label: "",
    cashOut: {
      label: "",
      amount: 0,
      growth: 0,
      startYear: 2021,
      endYear: 2021,
    },
  });

  const [selectedOutflowIndex, setSelectedOutflowIndex] = useState(0);

  const [selectedOutflow, setSelectedOutflow] = useState<UpdateExpenseInputDto>(
    {
      label: "",
      cashOut: {
        label: "",
        amount: 0,
        growth: 0,
        startYear: 2021,
        endYear: 2021,
      },
    }
  );

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  return (
    <Layout>
      <Card
        bordered={false}
        title="Expense"
        extra={
          <Button
            type="primary"
            onClick={() => setIsNewExpenseModalVisible(true)}
          >
            Add Expense
          </Button>
        }
      >
        <Table
          rowKey={(record) => {
            return record._id!.toString();
          }}
          pagination={false}
          columns={[
            {
              title: "Name",
              dataIndex: "label",
              key: "label",
            },

            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
              render: (record) =>
                currencySymbol + numberFormat(record, 0, ".", ","),
            },
            {
              title: "Growth",
              dataIndex: "growth",
              key: "growth",
              render: (record) => numberFormat(record * 100, 2, ".", ",") + "%",
            },
            {
              title: "Age",
              dataIndex: "age",
              key: "age",
            },
            {
              title: "Start Year",
              dataIndex: "startYear",
              key: "startYear",
            },
            {
              title: "End Year",
              dataIndex: "endYear",
              key: "endYear",
            },
            {
              title: "Actions",
              render: (value, record, recordIndex) => (
                <Space size="middle">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="2">
                          <Button
                            type="link"
                            onClick={async (e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              setSelectedOutflow(
                                data.inputs.expenses[recordIndex]
                              );
                              setSelectedOutflowIndex(recordIndex);
                              setOutflowEditModalVisible(true);
                            }}
                          >
                            Edit
                          </Button>
                        </Menu.Item>
                        <Menu.Item key="3">
                          <Button
                            type="link"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setSelectedOutflow(
                                data.inputs.expenses[recordIndex]
                              );
                              setSelectedOutflowIndex(recordIndex);

                              setIsDeleteOutflowModalVisible(true);
                            }}
                          >
                            Delete
                          </Button>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button
                      size="small"
                      onClick={async (e) => {
                        e.stopPropagation();
                        console.log(value);
                      }}
                    >
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </Space>
              ),
            },
          ]}
          dataSource={data.inputs.expenses.map((e) => e.cashOut)}
        />
      </Card>
      {/* New Inflow Modal */}
      <Modal
        title={"Add Expense"}
        visible={isNewExpenseModalVisible}
        okText={"Save"}
        onOk={async () => {
          dispatch(setLoadingAction(true));

          await dispatch(createInputsExpenseAction(data._id!, newOutflow));

          await dispatch(getDataAction(data._id!));
          dispatch(setLoadingAction(false));
          setIsNewExpenseModalVisible(false);
          setNewOutflow({
            label: "",
            cashOut: {
              amount: 0,
              endYear: 0,
              growth: 0,
              label: "",
              startYear: 0,
            },
          });
        }}
        onCancel={() => {
          setIsNewExpenseModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            form={form}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{}}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name of Outflow" }]}
                >
                  <Input
                    value={newOutflow.label}
                    onChange={(e) => {
                      setNewOutflow({
                        ...newOutflow,
                        label: e.target.value,
                        cashOut: {
                          ...newOutflow.cashOut,
                          label: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="amount"
                  label="Amount"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${newOutflow.cashOut.amount}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewOutflow({
                        ...newOutflow,
                        cashOut: {
                          ...newOutflow.cashOut,
                          amount: +e.target.value.replace(/\$\s?|(,*)/g, ""),
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="growth"
                  label="Growth"
                  rules={[
                    { required: true, message: "Inflation rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${newOutflow.cashOut.growth * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewOutflow({
                        ...newOutflow,
                        cashOut: {
                          ...newOutflow.cashOut,
                          growth:
                            parseFloat(e.target.value.replace("%", "")) / 100,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="startYear"
                  label="Start Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${newOutflow.cashOut.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewOutflow({
                        ...newOutflow,
                        cashOut: {
                          ...newOutflow.cashOut,
                          startYear: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="endYear"
                  label="End Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${newOutflow.cashOut.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewOutflow({
                        ...newOutflow,
                        cashOut: {
                          ...newOutflow.cashOut,
                          endYear: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>
      {/* Edit Inflow Modal */}
      <Modal
        title={"Edit Expense"}
        visible={outflowEditModalVisible}
        okText={"Save"}
        onOk={async () => {
          const newInput: UpdateExpenseInputDto = selectedOutflow;

          dispatch(
            updateInputsAction(
              data._id!,
              data.inputs.expenses[selectedOutflowIndex]._id!,
              newInput
            )
          );

          setSelectedOutflow({
            label: "",
            cashOut: {
              amount: 0,
              endYear: 0,
              growth: 0,
              label: "",
              startYear: 0,
            },
          });
          setOutflowEditModalVisible(false);
        }}
        onCancel={() => {
          setOutflowEditModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            form={editForm}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{}}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Name"
                  rules={[{ required: true, message: "Name of Outflow" }]}
                >
                  <Input
                    value={selectedOutflow.cashOut?.label}
                    onChange={(e) => {
                      setSelectedOutflow({
                        label: e.target.value,
                        ...selectedOutflow,
                        cashOut: {
                          ...selectedOutflow.cashOut!,
                          label: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Amount"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${selectedOutflow.cashOut!.amount}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setSelectedOutflow({
                        ...selectedOutflow,
                        cashOut: {
                          ...selectedOutflow.cashOut!,
                          amount: +e.target.value.replace(/\$\s?|(,*)/g, ""),
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Growth"
                  rules={[
                    { required: true, message: "Inflation rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${selectedOutflow.cashOut!.growth * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setSelectedOutflow({
                        ...selectedOutflow,
                        cashOut: {
                          ...selectedOutflow.cashOut!,
                          growth:
                            parseFloat(e.target.value.replace("%", "")) / 100,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Start Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${selectedOutflow.cashOut!.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setSelectedOutflow({
                        ...selectedOutflow,
                        cashOut: {
                          ...selectedOutflow.cashOut!,
                          growth: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="End Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${selectedOutflow.cashOut!.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setSelectedOutflow({
                        ...selectedOutflow,
                        cashOut: {
                          ...selectedOutflow.cashOut!,
                          endYear: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>
      {/* Delete Inflow Modal */}
      <Modal
        title="Delete inflow"
        visible={isOutflowDeleteModalVisible}
        onCancel={() => setIsDeleteOutflowModalVisible(false)}
        onOk={async () => {
          dispatch(setLoadingAction(true));
          dispatch(
            deleteInputsAcion(
              data._id!,
              data.inputs.expenses[selectedOutflowIndex]._id!
            )
          );
          setIsDeleteOutflowModalVisible(false);
          dispatch(setLoadingAction(false));
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
      >
        Are you sure you want to delete the Expense?
      </Modal>
    </Layout>
  );
};

export default Expense;
