import axios from "axios";
import calculateForecast from "../../helper/data/dummyForecast";
import IData from "../../interface/IData";
import IForecast from "../../interface/IForecast";
import { apiPlanRoute, apiRoute } from "../../routes/apiRoutes";

import { PlansApi, CreateAssetInputDto } from "@advicefront/plan-api";
import { Dispatch } from "redux";
import { setLoadingAction } from "../General/Loading";

const plansApi = new PlansApi({
  basePath: apiRoute,
  isJsonMime: () => true,
});

export const getDataAction = (planId: string) => async (dispatch: any) => {
  dispatch(setLoadingAction(true));
  const res = await plansApi.plansControllerFindOne(planId);

  const foundData: any = res.data;

  const data: IData = {
    _id: foundData._id,
    name: foundData.name,
    startYear: foundData.startYear,
    endYear: foundData.endYear,

    inputs: {
      income: [],
      expenses: [],
      bankAccounts: [],
      assets: [],
      liabilities: [],
    },
  };

  foundData.inputs.map((input: any) => {
    if (input.__t === "IncomeInput") {
      data.inputs.income.push(input);
    }

    if (input.__t === "ExpenseInput") {
      data.inputs.expenses.push(input);
    }

    if (input.__t === "BankAccountInput") {
      data.inputs.bankAccounts.push(input);
    }

    if (input.__t === "AssetInput") {
      data.inputs.assets.push(input);
    }
    if (input.__t === "LoanInput") {
      data.inputs.liabilities.push(input);
    }
  });

  dispatch({
    type: "SET_DATA",
    payload: data,
  });

  dispatch({
    type: "SET_FORECAST",
    payload: calculateForecast(foundData),
  });
  dispatch(setLoadingAction(false));
};

export const dataReducer = (
  state: IData = {
    _id: "",
    name: "",
    startYear: 0,
    endYear: 0,
    inputs: {
      income: [],
      expenses: [],
      bankAccounts: [],
      assets: [],
      liabilities: [],
    },
  },
  action: any
) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_DATA":
      return (state = payload);

    default:
      return state;
  }
};

export const forecastReducer = (
  state: IForecast = {
    years: [],
    liquidAssets: [],
    liquidAssetInflows: [],
    nonLiquidAssetsInflows: [],
    nonLiquidAssets: [],
    liquidAssetOutflows: [],
    nonLiquidAssetsLiabilities: [],
    inflows: [],
    liabilities: [],
    nonLiquidAssetsLiabilitiesCashouts: [],
    outflows: [],
    liabilityCashouts: [],
  },
  action: any
) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_FORECAST":
      return (state = payload);

    default:
      return state;
  }
};
