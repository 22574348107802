import {
  CreateClientPersonDtoLegalGenderEnum,
  CreateHouseholdDto,
} from "@advicefront/plan-api";
import {
  Modal,
  DatePicker,
  Form,
  Divider,
  Input,
  Radio,
  InputNumber,
  Switch,
} from "antd";
import moment from "moment";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createHouseholdAction } from "../../../../../redux/Households";
import { RootState } from "../../../../../redux/store";

type ModalProps = {
  title: string;
  addModalVisible: boolean;
  setAddModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddHouseholdModal = ({
  addModalVisible,
  setAddModalVisible,
  title,
}: ModalProps) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.loadingReducer);
  const [newHouseholdObject, setNewHouseholdObject] =
    useState<CreateHouseholdDto>({
      name: "",
      client: {
        dateOfBirth: moment().toISOString(),
        legalGender: CreateClientPersonDtoLegalGenderEnum.M,
        name: "",
        retirementAge: 65,
      },
      partner: {
        dateOfBirth: moment().toISOString(),
        legalGender: CreateClientPersonDtoLegalGenderEnum.F,
        name: "",
        retirementAge: 60,
      },
      dependents: [],
    });

  const [hasPartner, setHasPartner] = useState(false);

  const [addForm] = Form.useForm();

  const handleSubmit = async () => {
    await dispatch(createHouseholdAction(newHouseholdObject));

    setNewHouseholdObject({
      name: "",
      client: {
        dateOfBirth: moment.defaultFormatUtc,
        legalGender: CreateClientPersonDtoLegalGenderEnum.M,
        name: "",
        retirementAge: 65,
      },
      partner: {
        dateOfBirth: moment.defaultFormatUtc,
        legalGender: CreateClientPersonDtoLegalGenderEnum.F,
        name: "",
        retirementAge: 60,
      },
      dependents: [],
    });

    addForm.resetFields();
    setHasPartner(false);

    setAddModalVisible(false);
  };
  return (
    <Modal
      confirmLoading={loading}
      title={title}
      visible={addModalVisible}
      onOk={() => {
        addForm.submit();
      }}
      onCancel={() => setAddModalVisible(false)}
    >
      <Form
        form={addForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          clientdateofbirth: moment(),
          clientlegalgender: newHouseholdObject.client.legalGender,
          clientretirementage: newHouseholdObject.client.retirementAge,
          partnerdateofbirth: moment(),
          partnerlegalgender: newHouseholdObject.partner!.legalGender,
          partnerretirementage: newHouseholdObject.partner!.retirementAge,
          hasPartner: false,
        }}
        onFinish={() => handleSubmit()}
      >
        <Form.Item
          label="Household Name"
          name="householdname"
          rules={[{ required: true, message: "Household name is required" }]}
        >
          <Input
            placeholder="John's Plan"
            onChange={(e) =>
              setNewHouseholdObject({
                ...newHouseholdObject,
                name: e.target.value,
              })
            }
          />
        </Form.Item>

        <Divider plain>Client Information</Divider>

        <Form.Item
          label="Client Name"
          name="clientname"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input
            placeholder="John Doe"
            onChange={(e) =>
              setNewHouseholdObject({
                ...newHouseholdObject,
                client: { ...newHouseholdObject.client, name: e.target.value },
              })
            }
          />
        </Form.Item>

        <Form.Item
          label="Date of birth"
          name="clientdateofbirth"
          rules={[{ required: true, message: "Date of birth is required" }]}
        >
          <DatePicker
            onChange={(e) => {
              setNewHouseholdObject({
                ...newHouseholdObject,
                client: {
                  ...newHouseholdObject.client,
                  dateOfBirth: moment(e).toISOString(),
                },
              });
            }}
          />
        </Form.Item>

        <Form.Item
          label="Retirement Age"
          name="clientretirementage"
          rules={[{ required: true, message: "Retirement age is required" }]}
        >
          <InputNumber
            onChange={(e) => {
              setNewHouseholdObject({
                ...newHouseholdObject,
                client: {
                  ...newHouseholdObject.client,
                  retirementAge: +e,
                },
              });
            }}
          />
        </Form.Item>

        <Form.Item label="Legal Gender" name="clientlegalgender">
          <Radio.Group
            optionType="button"
            options={[
              { label: "Male", value: CreateClientPersonDtoLegalGenderEnum.M },
              {
                label: "Female",
                value: CreateClientPersonDtoLegalGenderEnum.F,
              },
            ]}
            onChange={(e) => {
              setNewHouseholdObject({
                ...newHouseholdObject,
                client: {
                  ...newHouseholdObject.client,
                  legalGender: e.target.value,
                },
              });
            }}
          />
        </Form.Item>

        {/* Partner Information */}
        <Form.Item label="Has Partner?" name="haspartner">
          <Switch
            checked={hasPartner}
            unCheckedChildren="No"
            checkedChildren="Yes"
            onChange={(e) => setHasPartner(e)}
          />
        </Form.Item>

        {hasPartner && (
          <>
            <Divider plain>Client Information</Divider>
            <Form.Item
              label="Partner Name"
              name="partnername"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input
                placeholder="Jane Doe"
                onChange={(e) =>
                  setNewHouseholdObject({
                    ...newHouseholdObject,
                    partner: {
                      ...newHouseholdObject.partner!,
                      name: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
            <Form.Item
              label="Date of birth"
              name="partnerdateofbirth"
              rules={[{ required: true, message: "Date of birth is required" }]}
            >
              <DatePicker
                onChange={(e) => {
                  setNewHouseholdObject({
                    ...newHouseholdObject,
                    partner: {
                      ...newHouseholdObject.partner!,
                      dateOfBirth: moment(e).toISOString(),
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Retirement Age"
              name="partnerretirementage"
              rules={[
                { required: true, message: "Retirement age is required" },
              ]}
            >
              <InputNumber
                onChange={(e) => {
                  setNewHouseholdObject({
                    ...newHouseholdObject,
                    partner: {
                      ...newHouseholdObject.partner!,
                      retirementAge: +e,
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Legal Gender" name="partnerlegalgender">
              <Radio.Group
                optionType="button"
                options={[
                  {
                    label: "Male",
                    value: CreateClientPersonDtoLegalGenderEnum.M,
                  },
                  {
                    label: "Female",
                    value: CreateClientPersonDtoLegalGenderEnum.F,
                  },
                ]}
                onChange={(e) => {
                  setNewHouseholdObject({
                    ...newHouseholdObject,
                    partner: {
                      ...newHouseholdObject.partner!,
                      legalGender: e.target.value,
                    },
                  });
                }}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default AddHouseholdModal;
