import IData from "../../interface/IData";
import IForecast from "../../interface/IForecast";
import IForecastObject from "../../interface/IForecastObject";
import calcInflow from "./formulae/inflowFormula";
import calcOutflow from "./formulae/outflowFormula";
import { calcFutureValue } from "./formulae/propertyValue";

//ry = remaining years (n+1)
export const calculateForecast = (apiData: any) => {
  const data: IData = {
    _id: apiData._id,
    name: apiData.name,
    startYear: apiData.startYear,
    endYear: apiData.endYear,

    inputs: {
      income: [],
      expenses: [],
      bankAccounts: [],
      assets: [],
      liabilities: [],
    },
  };

  apiData.inputs.forEach((input: any) => {
    if (input.__t === "IncomeInput") {
      data.inputs.income.push(input);
    }

    if (input.__t === "ExpenseInput") {
      data.inputs.expenses.push(input);
    }

    if (input.__t === "BankAccountInput") {
      data.inputs.bankAccounts.push(input);
    }

    if (input.__t === "AssetInput") {
      data.inputs.assets.push(input);
    }

    if (input.__t === "LoanInput") {
      data.inputs.liabilities.push(input);
    }
  });

  let years: number[] = [];

  const forecastStartYear = 2021;
  const forecastEndYear = 2040;

  const dummyForecast: IForecast = {
    years,
    inflows: [],
    outflows: [],
    liquidAssets: [],
    liquidAssetInflows: [],
    liquidAssetOutflows: [],
    nonLiquidAssets: [],
    nonLiquidAssetsInflows: [],
    nonLiquidAssetsLiabilities: [],
    nonLiquidAssetsLiabilitiesCashouts: [],
    liabilities: [],
    liabilityCashouts: [],
  };

  for (
    let yearIndex = 0;
    yearIndex < forecastEndYear - forecastStartYear + 1;
    yearIndex++
  ) {
    const year = yearIndex + forecastStartYear;
    years.push(year);

    //calculate initial year
    if (yearIndex === 0) {
      //calculate inflows initial year
      data.inputs.income.forEach((inflow) => {
        const newInflowObject: IForecastObject = {
          id: inflow._id!,
          name: inflow.label,
          amounts: [],
        };

        let amount = calcInflow(forecastStartYear, 0, inflow.cashIn);
        newInflowObject.amounts.push(amount);
        dummyForecast.inflows.push(newInflowObject);
      });

      //calculate outflows initial year
      data.inputs.expenses.forEach((outflow) => {
        const newOutflowObject: IForecastObject = {
          id: outflow._id,
          name: outflow.label,
          amounts: [],
        };

        let amount = calcOutflow(forecastStartYear, 0, outflow.cashOut);
        newOutflowObject.amounts.push(amount);
        dummyForecast.outflows.push(newOutflowObject);
      });

      //calculate liquid asset inflows initial year
      data.inputs.bankAccounts.forEach((asset) => {
        asset.withdrawals.forEach((inflow) => {
          const newInflowObject: IForecastObject = {
            id: inflow._id!,
            name: inflow.label,
            amounts: [],
          };

          let amount = 0;

          if (yearIndex >= inflow.startYear && yearIndex <= inflow.endYear) {
            amount = (inflow.amount * (1 + inflow.growth)) ** 0;
          }
          newInflowObject.amounts.push(amount);
          dummyForecast.liquidAssetInflows.push(newInflowObject);
        });
      });

      //calculate liquid asset outflows initial year
      data.inputs.bankAccounts.forEach((asset) => {
        asset.contributions.forEach((outflow) => {
          const newOutflowObject: IForecastObject = {
            id: outflow._id!,
            name: outflow.label,
            amounts: [],
          };

          let amount = calcInflow(forecastStartYear, 0, outflow);
          newOutflowObject.amounts.push(amount);
          dummyForecast.liquidAssetOutflows.push(newOutflowObject);
        });
      });

      //calculate liquid asset
      data.inputs.bankAccounts.forEach((asset) => {
        const newAssetObject: IForecastObject = {
          id: asset._id,
          name: asset.label,
          amounts: [],
        };

        let incomeAmount = 0;

        asset.withdrawals.forEach((inflow) => {
          if (yearIndex >= inflow.startYear && yearIndex <= inflow.endYear) {
            incomeAmount += (inflow.amount * (1 + inflow.growth)) ** 0;
          }
        });

        let outflowAmount = 0;
        asset.contributions.forEach((outflow) => {
          outflowAmount += calcInflow(forecastStartYear, 0, outflow);
        });

        let assetAmount =
          asset.bankAccount.initialValue + incomeAmount + outflowAmount;

        newAssetObject.amounts.push(assetAmount);
        dummyForecast.liquidAssets.push(newAssetObject);
      });

      //calculate non liquid assets
      data.inputs.assets.forEach((asset) => {
        const newAssetObject: IForecastObject = {
          id: asset._id,
          name: asset.label,
          amounts: [],
        };

        let amount = 0;

        if (year >= asset.asset.startYear && year <= asset.asset.endYear) {
          amount =
            asset.asset.currentValue * (1 + asset.asset.growth) ** yearIndex;
        }

        newAssetObject.amounts.push(amount);
        dummyForecast.nonLiquidAssets.push(newAssetObject);
      });

      //calculate non liquid asset liabilities/cashouts
      data.inputs.assets.forEach((asset) => {
        const liabilityObject: IForecastObject = {
          id: asset.loan.liability._id,
          name: asset.loan.liability.label,
          amounts: [],
        };

        let amount = 0;

        if (
          year >= asset.loan.loanPayment.startYear &&
          year <= asset.loan.loanPayment.endYear
        ) {
          const fv = -calcFutureValue(
            asset.loan.loanPayment.growth,
            year - asset.loan.loanPayment.startYear,
            -asset.loan.loanPayment.amount,
            asset.loan.liability.balance *
              (1 + asset.loan.liability.rate) **
                (asset.loan.liability.startYear - forecastStartYear),
            0
          );

          if (fv <= 0 || year > asset.loan.loanPayment.payAllPeriod) {
            amount = 0;
          } else {
            amount = fv;
          }
        }

        const liabilityCashout: IForecastObject = {
          id: asset.loan.loanPayment._id!,
          name: asset.loan.loanPayment.label,
          amounts: [],
        };

        let cashoutAmount = 0;

        if (
          year >= asset.loan.loanPayment.startYear &&
          year <= asset.loan.loanPayment.endYear
        ) {
          if (year === asset.loan.loanPayment.payAllPeriod) {
            cashoutAmount = asset.loan.loanPayment.amount + amount;
          } else {
            cashoutAmount = asset.loan.loanPayment.amount;
          }
        }

        liabilityCashout.amounts.push(cashoutAmount);
        dummyForecast.nonLiquidAssetsLiabilitiesCashouts.push(liabilityCashout);

        liabilityObject.amounts.push(amount);
        dummyForecast.nonLiquidAssetsLiabilities.push(liabilityObject);
      });

      //calculate non liquid assets inflows
      data.inputs.assets.forEach((asset) => {
        const assetIncomeObject: IForecastObject = {
          id: asset.assetIncome._id!,
          name: asset.assetIncome.label,
          amounts: [],
        };

        let amount = calcInflow(forecastStartYear, 0, asset.assetIncome);
        assetIncomeObject.amounts.push(amount);
        dummyForecast.nonLiquidAssetsInflows.push(assetIncomeObject);

        const assetSellObject: IForecastObject = {
          id: asset.assetSale._id!,
          name: asset.assetSale.label,
          amounts: [],
        };

        let amount2 = 0;

        if (
          year >= asset.assetSale.startYear &&
          year <= asset.assetSale.startYear
        ) {
          amount2 = calcInflow(forecastStartYear, 0, asset.assetSale);
        }
        assetSellObject.amounts.push(amount2);
        dummyForecast.nonLiquidAssetsInflows.push(assetSellObject);
      });

      //calculate non liquid asset liabilities/cashouts
      data.inputs.liabilities.forEach((liability) => {
        const liabilityObject: IForecastObject = {
          id: liability.loan._id,
          name: liability.loan.label,
          amounts: [],
        };

        let amount = 0;

        if (
          year >= liability.loanPayment.startYear &&
          year <= liability.loanPayment.endYear
        ) {
          const fv = -calcFutureValue(
            liability.loanPayment.growth,
            year - liability.loanPayment.startYear,
            -liability.loanPayment.amount,
            liability.loan.balance *
              (1 + liability.loan.rate) **
                (liability.loan.startYear - forecastStartYear),
            0
          );

          if (fv <= 0 || year > liability.loanPayment.payAllPeriod) {
            amount = 0;
          } else {
            amount = fv;
          }
        }

        const liabilityCashout: IForecastObject = {
          id: liability.loanPayment._id!,
          name: liability.loanPayment.label,
          amounts: [],
        };

        let cashoutAmount = 0;

        if (
          year >= liability.loanPayment.startYear &&
          year <= liability.loanPayment.endYear
        ) {
          if (year === liability.loanPayment.payAllPeriod) {
            cashoutAmount = liability.loanPayment.amount + amount;
          } else {
            cashoutAmount = liability.loanPayment.amount;
          }
        }

        liabilityCashout.amounts.push(cashoutAmount);
        dummyForecast.liabilityCashouts.push(liabilityCashout);

        liabilityObject.amounts.push(amount);
        dummyForecast.liabilities.push(liabilityObject);
      });
    } else {
      ///////////////////////////////////////////////////////////////////////////////////////// remaining years

      //calculate inflows simple ry
      data.inputs.income.forEach((inflow) => {
        let amount = calcInflow(
          forecastStartYear + yearIndex,
          yearIndex,
          inflow.cashIn
        );

        dummyForecast.inflows
          .find((i) => i.id === inflow._id)
          ?.amounts.push(amount);
      });

      //calculate outflows simple ry
      data.inputs.expenses.forEach((outflow) => {
        let amount = calcInflow(
          forecastStartYear + yearIndex,
          yearIndex,
          outflow.cashOut
        );

        dummyForecast.outflows
          .find((o) => o.id === outflow._id)
          ?.amounts.push(amount);
      });

      //calculate liquid assets inflows ry
      data.inputs.bankAccounts.forEach((asset, assetIndex) => {
        asset.withdrawals.forEach((inflow) => {
          let amount = 0;

          if (year >= inflow.startYear && year <= inflow.endYear) {
            if (
              inflow.amount * (1 + inflow.growth) ** yearIndex >
              dummyForecast.liquidAssets.find((i) => i.id === asset._id)!
                .amounts[yearIndex - 1]
            ) {
              amount = dummyForecast.liquidAssets.find(
                (i) => i.id === asset._id
              )!.amounts[yearIndex - 1];
            } else {
              amount = inflow.amount * (1 + inflow.growth) ** yearIndex;
            }
          }

          dummyForecast.liquidAssetInflows
            .find((ai) => ai.id === inflow._id)!
            .amounts.push(amount);
        });
      });

      //calculate liquid assets outflows ry
      data.inputs.bankAccounts.forEach((asset, assetIndex) => {
        asset.contributions.forEach((outflow) => {
          const amount = calcInflow(year, yearIndex, outflow);
          dummyForecast.liquidAssetOutflows
            .find((ao) => ao.id === outflow._id)!
            .amounts.push(amount);
        });
      });

      //calculate liquid assets ry
      data.inputs.bankAccounts.forEach((asset, assetIndex) => {
        let assetInflowTotal = 0;
        asset.withdrawals.forEach((inflow, inflowIndex) => {
          if (year >= inflow.startYear && year <= inflow.endYear) {
            if (
              inflow.amount * (1 + inflow.growth) ** yearIndex >
              dummyForecast.liquidAssets[assetIndex].amounts[yearIndex - 1]
            ) {
              assetInflowTotal +=
                dummyForecast.liquidAssets[assetIndex].amounts[yearIndex - 1];
            } else {
              assetInflowTotal +=
                inflow.amount * (1 + inflow.growth) ** yearIndex;
            }
          }
        });
        let assetOutflowTotal = 0;
        asset.contributions.forEach((outflow, outflowIndex) => {
          assetOutflowTotal += calcInflow(year, yearIndex, outflow);
        });

        let amount = 0;
        if (
          dummyForecast.liquidAssets[assetIndex].amounts[yearIndex - 1] -
            assetInflowTotal +
            assetOutflowTotal <
          0
        ) {
          amount = 0;
        } else {
          amount =
            dummyForecast.liquidAssets[assetIndex].amounts[yearIndex - 1] -
            assetInflowTotal +
            assetOutflowTotal;
        }

        dummyForecast.liquidAssets
          .find((la) => la.id === asset._id)!
          .amounts.push(amount);
      });

      //calculate non liquid assets ry
      data.inputs.assets.forEach((asset, assetIndex) => {
        let amount = 0;

        if (year >= asset.asset.startYear && year <= asset.asset.endYear) {
          amount =
            asset.asset.currentValue * (1 + asset.asset.growth) ** yearIndex;
        }

        dummyForecast.nonLiquidAssets
          .find((nla) => nla.id === asset._id)!
          .amounts.push(amount);
      });

      //calculate non liquid asset liabilities/cashouts ry
      data.inputs.assets.forEach((asset, assetIndex) => {
        let amount = 0;

        if (
          year >= asset.loan.loanPayment.startYear &&
          year <= asset.loan.loanPayment.endYear
        ) {
          const fv = -calcFutureValue(
            asset.loan.loanPayment.growth,
            year - asset.loan.loanPayment.startYear,
            -asset.loan.loanPayment.amount,
            asset.loan.liability.balance *
              (1 + asset.loan.liability.rate) **
                (asset.loan.liability.startYear - forecastStartYear),
            0
          );

          if (fv <= 0 || year > asset.loan.loanPayment.payAllPeriod) {
            amount = 0;
          } else {
            amount = fv;
          }
        }

        let cashoutAmount = 0;

        if (
          year >= asset.loan.loanPayment.startYear &&
          year <= asset.loan.loanPayment.endYear
        ) {
          if (year === asset.loan.loanPayment.payAllPeriod) {
            cashoutAmount = asset.loan.loanPayment.amount + amount;
          } else {
            cashoutAmount = asset.loan.loanPayment.amount;
          }
        }

        if (year > asset.assetSale.startYear) {
          cashoutAmount = 0;
        }

        dummyForecast.nonLiquidAssetsLiabilitiesCashouts
          .find((nlaco) => nlaco.id === asset.loan.loanPayment._id)!
          .amounts.push(cashoutAmount);

        dummyForecast.nonLiquidAssetsLiabilities
          .find((nlal) => nlal.id === asset.loan.liability._id)!
          .amounts.push(amount);
      });

      //calculate non liquid assets inflows ry
      data.inputs.assets.forEach((asset, assetIndex) => {
        let amount = calcInflow(
          forecastStartYear + yearIndex,
          yearIndex,
          asset.assetIncome
        );

        let amount2 = calcInflow(
          forecastStartYear + yearIndex,
          yearIndex,
          asset.assetSale
        );

        dummyForecast.nonLiquidAssetsInflows
          .find((nlai) => nlai.id === asset.assetIncome._id)
          ?.amounts.push(amount);
        dummyForecast.nonLiquidAssetsInflows
          .find((nlai) => nlai.id === asset.assetSale._id)
          ?.amounts.push(amount2);
      });

      //calculate  liabilities/cashouts ry
      data.inputs.liabilities.forEach((liability, assetIndex) => {
        let amount = 0;

        if (
          year >= liability.loanPayment.startYear &&
          year <= liability.loanPayment.endYear
        ) {
          const fv = -calcFutureValue(
            liability.loanPayment.growth,
            year - liability.loanPayment.startYear,
            -liability.loanPayment.amount,
            liability.loan.balance *
              (1 + liability.loan.rate) **
                (liability.loan.startYear - forecastStartYear),
            0
          );

          if (fv <= 0 || year > liability.loanPayment.payAllPeriod) {
            amount = 0;
          } else {
            amount = fv;
          }
        }

        let cashoutAmount = 0;

        if (
          year >= liability.loanPayment.startYear &&
          year <= liability.loanPayment.endYear
        ) {
          if (year === liability.loanPayment.payAllPeriod) {
            cashoutAmount = liability.loanPayment.amount + amount;
          } else {
            cashoutAmount = liability.loanPayment.amount;
          }
        }

        // if (year > liability.assetSale.startYear) {
        //   cashoutAmount = 0;
        // }

        dummyForecast.liabilityCashouts
          .find((nlaco) => nlaco.id === liability.loanPayment._id)!
          .amounts.push(cashoutAmount);

        dummyForecast.liabilities
          .find((nlal) => nlal.id === liability.loan._id)!
          .amounts.push(amount);
      });
    }
  }

  return dummyForecast;
};

export default calculateForecast;
