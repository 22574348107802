import React, { useEffect, useState } from "react";
import { Card, Col, Layout, Row, Table, Tabs } from "antd";
import { RootStateOrAny, useSelector } from "react-redux";
import IForecast from "../../interface/IForecast";

import highcharts, { numberFormat } from "highcharts";
import HighchartsReact from "highcharts-react-official";

const { TabPane } = Tabs;

const Forecast = () => {
  const forecast: IForecast = useSelector(
    (state: RootStateOrAny) => state.forecastReducer
  );

  const [chartOptions, setChartOptions] = useState<highcharts.Options>({
    chart: {
      alignTicks: false,
      ignoreHiddenSeries: true,
      animation: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
      align: "left",
    },
    xAxis: {
      crosshair: true,
      labels: {
        rotation: 0,

        overflow: "justify",
        step: 4,
      },
      categories: [
        ...forecast.years.map((s, i) => {
          return `<b>${s}</b> <br>`;
        }),
      ],
      min: 0,
      max: forecast.years.length - 1,
    },
    yAxis: {
      gridLineWidth: 0,
      plotLines: [
        {
          color: "black",
          width: 1,
          value: 0,
          zIndex: 9,
        },
      ],
      max: null,
      title: {
        text: "",
      },
      labels: {
        useHTML: true,
        formatter: function (e: any) {
          return "£" + e.value / 1000 + "k";
        },
      },
    },
    plotOptions: {
      series: {
        animation: false,
        point: {
          events: {
            click: (e) => {},
          },
        },
      },
      column: {
        stacking: "normal",
        borderWidth: 1,
        pointPadding: 0,
        groupPadding: 0,
        pointRange: 1,
        events: {
          click: (e) => {
            // console.log(e.point.x)
          },
        },
        point: {
          events: {
            select: (event) => {
              // console.log(event);
            },
          },
        },
      },
    },
    series: [],
  });

  const [chartOptionsBalanceSheet, setChartOptionsBalanceSheet] =
    useState<highcharts.Options>({
      chart: {
        alignTicks: false,
        ignoreHiddenSeries: true,
        animation: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
        align: "left",
      },
      xAxis: {
        crosshair: true,
        labels: {
          rotation: 0,

          overflow: "justify",
          step: 4,
        },
        categories: [
          ...forecast.years.map((s, i) => {
            return `<b>${s}</b> <br>`;
          }),
        ],
        min: 0,
        max: forecast.years.length - 1,
      },
      yAxis: {
        gridLineWidth: 0,
        plotLines: [
          {
            color: "black",
            width: 1,
            value: 0,
            zIndex: 9,
          },
        ],
        max: null,
        title: {
          text: "",
        },
        labels: {
          useHTML: true,
          formatter: function (e: any) {
            return "£" + e.value / 1000 + "k";
          },
        },
      },
      plotOptions: {
        series: {
          animation: false,
          point: {
            events: {
              click: (e) => {},
            },
          },
        },
        column: {
          stacking: "normal",
          borderWidth: 1,
          pointPadding: 0,
          groupPadding: 0,
          pointRange: 1,
          events: {
            click: (e) => {
              // console.log(e.point.x)
            },
          },
          point: {
            events: {
              select: (event) => {
                // console.log(event);
              },
            },
          },
        },
      },
      series: [],
    });

  useEffect(() => {
    const newSeries: any = [];

    forecast.inflows.forEach((inflow) => {
      newSeries.push({
        type: "column",
        name: inflow.name,
        data: inflow.amounts.map((amount) => {
          return Math.round(amount);
        }),
      });
    });

    forecast.liquidAssetInflows.forEach((inflow) => {
      newSeries.push({
        type: "column",
        name: inflow.name,
        data: inflow.amounts.map((amount) => {
          return Math.round(amount);
        }),
      });
    });

    forecast.nonLiquidAssetsInflows.forEach((inflow) => {
      newSeries.push({
        type: "column",
        name: inflow.name,
        data: inflow.amounts.map((amount) => {
          return Math.round(amount);
        }),
      });
    });

    forecast.outflows.forEach((outflow) => {
      newSeries.push({
        type: "column",
        name: outflow.name,
        data: outflow.amounts.map((amount) => {
          return -Math.round(amount);
        }),
      });
    });

    forecast.liquidAssetOutflows.forEach((outflow) => {
      newSeries.push({
        type: "column",
        name: outflow.name,
        data: outflow.amounts.map((amount) => {
          return -Math.round(amount);
        }),
      });
    });

    forecast.nonLiquidAssetsLiabilitiesCashouts.forEach((outflow) => {
      newSeries.push({
        type: "column",
        name: outflow.name,
        data: outflow.amounts.map((amount) => {
          return -Math.round(amount);
        }),
      });
    });

    forecast.liabilityCashouts.forEach((outflow) => {
      newSeries.push({
        type: "column",
        name: outflow.name,
        data: outflow.amounts.map((amount) => {
          return -Math.round(amount);
        }),
      });
    });

    setChartOptions({ ...chartOptions, series: newSeries });
    console.log("Hello");
  }, [forecast, chartOptions]);

  useEffect(() => {
    const tempSeries: any = [];

    forecast.liquidAssets.forEach((l) => {
      tempSeries.push({
        type: "column",
        name: l.name,
        data: l.amounts.map((amount) => {
          return Math.round(amount);
        }),
      });
    });

    forecast.nonLiquidAssets.forEach((l) => {
      tempSeries.push({
        type: "column",
        name: l.name,
        data: l.amounts.map((amount) => {
          return Math.round(amount);
        }),
      });
    });

    forecast.nonLiquidAssetsLiabilities.forEach((l) => {
      tempSeries.push({
        type: "column",
        name: l.name,
        data: l.amounts.map((amount) => {
          return -Math.round(amount);
        }),
      });
    });

    forecast.liabilities.forEach((l) => {
      tempSeries.push({
        type: "column",
        name: l.name,
        data: l.amounts.map((amount) => {
          return -Math.round(amount);
        }),
      });
    });

    setChartOptionsBalanceSheet({
      ...chartOptionsBalanceSheet,
      series: tempSeries,
    });
  }, [forecast]);

  const cashflowColumns: any = [
    {
      title: "Name",
      dataIndex: "label",
      key: "name",
      fixed: "left",
    },
    ...forecast.years.map((year, yearIndex) => {
      return {
        title: year.toString(),
        dataIndex: "year" + yearIndex,
        key: "year",
      };
    }),
  ];

  const [cashflowTableData, setCashflowTableData] = useState<any[]>([]);

  useEffect(() => {
    let newTableData: any[] = [];

    //set simple inflows
    const normalInflowData: any[] = [];
    forecast.inflows.map((inflow, inflowIndex) => {
      normalInflowData.push({
        key: inflowIndex + "normalInflow",
        label: inflow.name,
      });
      inflow.amounts.map((amount, amountIndex) => {
        normalInflowData[inflowIndex]["year" + amountIndex] = numberFormat(
          amount,
          0,
          ".",
          ","
        );
      });
    });

    //set liquid asset inflows data
    const liquidAssetInflows: any[] = [];
    forecast.liquidAssetInflows.map((inflow, inflowIndex) => {
      liquidAssetInflows.push({
        key: inflowIndex + "normalInflow",
        label: inflow.name,
      });
      inflow.amounts.map((amount, amountIndex) => {
        liquidAssetInflows[inflowIndex]["year" + amountIndex] = numberFormat(
          amount,
          0,
          ".",
          ","
        );
      });
    });

    //set non liquid asset inflows
    const nonLiquidAssetsInflows: any[] = [];
    forecast.nonLiquidAssetsInflows.map((inflow, inflowIndex) => {
      nonLiquidAssetsInflows.push({
        key: inflowIndex + "normalInflow",
        label: inflow.name,
      });
      inflow.amounts.map((amount, amountIndex) => {
        nonLiquidAssetsInflows[inflowIndex]["year" + amountIndex] =
          numberFormat(amount, 0, ".", ",");
      });
    });

    //set simple outflow data
    const normalOutflowsData: any[] = [];
    forecast.outflows.map((outflow, outflowIndex) => {
      normalOutflowsData.push({
        key: outflowIndex + "normalInflow",
        label: outflow.name,
      });
      outflow.amounts.map((amount, amountIndex) => {
        normalOutflowsData[outflowIndex]["year" + amountIndex] = numberFormat(
          -amount,
          0,
          ".",
          ","
        );
      });
    });

    //set liquid asset outflows data
    const liquidAssetOutflows: any[] = [];
    forecast.liquidAssetOutflows.map((outflow, outflowIndex) => {
      liquidAssetOutflows.push({
        key: outflowIndex + "normalInflow",
        label: outflow.name,
      });
      outflow.amounts.map((amount, amountIndex) => {
        liquidAssetOutflows[outflowIndex]["year" + amountIndex] = numberFormat(
          -amount,
          0,
          ".",
          ","
        );
      });
    });

    //set non liquid assets cash outs

    const nonLiquidAssetOutflows: any[] = [];
    forecast.nonLiquidAssetsLiabilitiesCashouts.map((outflow, outflowIndex) => {
      nonLiquidAssetOutflows.push({
        key: outflowIndex + "normalInflow",
        label: outflow.name,
      });
      outflow.amounts.map((amount, amountIndex) => {
        nonLiquidAssetOutflows[outflowIndex]["year" + amountIndex] =
          numberFormat(-amount, 0, ".", ",");
      });
    });

    const liabilityOutflows: any[] = [];
    forecast.liabilityCashouts.map((outflow, outflowIndex) => {
      liabilityOutflows.push({
        key: outflowIndex + "normalInflow",
        label: outflow.name,
      });
      outflow.amounts.map((amount, amountIndex) => {
        liabilityOutflows[outflowIndex]["year" + amountIndex] = numberFormat(
          -amount,
          0,
          ".",
          ","
        );
      });
    });

    newTableData = [
      ...normalInflowData,
      ...liquidAssetInflows,
      ...nonLiquidAssetsInflows,
      ...normalOutflowsData,
      ...liquidAssetOutflows,
      ...nonLiquidAssetOutflows,
      ...liabilityOutflows,
    ];
    setCashflowTableData(newTableData);
  }, [forecast]);

  const [balanceTableData, setBalanceTableData] = useState<any[]>([]);

  useEffect(() => {
    let newTableData: any[] = [];

    //set liquid assets data
    const liquidAssetsData: any[] = [];
    forecast.liquidAssets.map((asset, assetIndex) => {
      liquidAssetsData.push({
        key: assetIndex + "normalasset",
        label: asset.name,
      });
      asset.amounts.map((amount, amountIndex) => {
        liquidAssetsData[assetIndex]["year" + amountIndex] = numberFormat(
          amount,
          0,
          ".",
          ","
        );
      });
    });

    //set non liquid assets data
    const nonLiquidAssetsData: any[] = [];
    forecast.nonLiquidAssets.map((asset, assetIndex) => {
      nonLiquidAssetsData.push({
        key: assetIndex + "normalasset",
        label: asset.name,
      });
      asset.amounts.map((amount, amountIndex) => {
        nonLiquidAssetsData[assetIndex]["year" + amountIndex] = numberFormat(
          amount,
          0,
          ".",
          ","
        );
      });
    });

    //set non liquid assets data
    const liabilitiesData: any[] = [];
    forecast.liabilities.map((asset, assetIndex) => {
      liabilitiesData.push({
        key: assetIndex + "normalasset",
        label: asset.name,
      });
      asset.amounts.map((amount, amountIndex) => {
        liabilitiesData[assetIndex]["year" + amountIndex] = numberFormat(
          amount,
          0,
          ".",
          ","
        );
      });
    });

    //set non liquid assets liabilities data
    const nonLiquidAssetLiabilitiesData: any[] = [];
    forecast.nonLiquidAssetsLiabilities.map((asset, assetIndex) => {
      nonLiquidAssetLiabilitiesData.push({
        key: assetIndex + "normalasset",
        label: asset.name,
      });
      asset.amounts.map((amount, amountIndex) => {
        nonLiquidAssetLiabilitiesData[assetIndex]["year" + amountIndex] =
          numberFormat(amount, 0, ".", ",");
      });
    });

    newTableData = [
      ...liquidAssetsData,
      ...nonLiquidAssetsData,
      ...liabilitiesData,
      ...nonLiquidAssetLiabilitiesData,
    ];
    setBalanceTableData(newTableData);
  }, [forecast]);

  return (
    <Layout>
      <Card bordered={false}>
        <Tabs
          style={{
            backgroundColor: "white",
          }}
        >
          <TabPane tab="Cashflow" key="1">
            <Row>
              <Col span={24}>
                <HighchartsReact
                  highcharts={highcharts}
                  options={chartOptions}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Table
                  size="small"
                  scroll={{ x: true }}
                  columns={cashflowColumns}
                  dataSource={cashflowTableData}
                  pagination={false}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Balance Sheet" key="2">
            <Row>
              <Col span={24}>
                <HighchartsReact
                  highcharts={highcharts}
                  options={chartOptionsBalanceSheet}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Table
                  size="small"
                  scroll={{ x: true }}
                  columns={cashflowColumns}
                  dataSource={balanceTableData}
                  pagination={false}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Card>
    </Layout>
  );
};

export default Forecast;
