import { Card, Tabs } from "antd";
import React from "react";
import Income from "./Income";
import Expenses from "./Expenses";
import LiquidAssets from "./LiquidAssets";
import NonLiquidAssets from "./NonLiquidAssets";
import Liabilities from "./Liabilities";

const { TabPane } = Tabs;

const index = () => {
  return (
    <Card bordered={false}>
      <Tabs defaultActiveKey="1" onChange={() => {}}>
        <TabPane tab="Employment/Income" key="1">
          <Income />
        </TabPane>
        <TabPane tab="Expenses" key="2">
          <Expenses />
        </TabPane>

        <TabPane tab="Savings and Investments" key="4">
          <LiquidAssets />
        </TabPane>
        <TabPane tab="Property/Assets" key="5">
          <NonLiquidAssets />
        </TabPane>
        <TabPane tab="Liabilities" key="6">
          <Liabilities />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default index;
