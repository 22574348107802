export const apiRoute = "https://plan-api.adviceatom.com";

export const apiPlanRoute = apiRoute + "/plans";

export const apiIncomeRoute = (planId: string): string => {
  return `${apiPlanRoute}/${planId}/inputs/income`;
};

export const apiExpenseRoute = (planId: string): string => {
  return `${apiPlanRoute}/${planId}/inputs/expense`;
};
