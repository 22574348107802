import { Button, Card, Col, Popconfirm, Row, Table } from "antd";
import { Fragment, useState } from "react";

import HouseholdTableCard from "./HouseholdTableCard";
import HouseholdDependantsTable from "./HouseholdDependantsTable";
import AddModal from "../modals/AddHouseholdModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import IHouseholds from "../../../../../interface/IHouseholds";
import DeleteHouseholdModal from "../modals/DeleteHouseholdModal";
import {
  deleteHouseholdAction,
  setActiveHouseholdAction,
} from "../../../../../redux/Households";
import { getHouseholdPlansAction } from "../../../../../redux/Plan";
import { useHistory } from "react-router";
import { plansRoute } from "../../../../../routes/navRoutes";

const HouseholdsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector((state: RootState) => state.loadingReducer);

  const [addModalVisible, setAddModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const tableData: IHouseholds[] = useSelector(
    (state: RootState) => state.householdsReducer
  );

  const [selectedHouseholdId, setSelectedHouseholdId] = useState("");
  const tableColumns = [
    {
      title: "Household name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Client name",
      render: (record: any) => record.client.name,
      key: "clientname",
    },
    {
      title: "Partner name",
      render: (record: any) => record.partner.name,
      key: "partnername",
    },
    {
      title: "Actions",
      render: (record: IHouseholds) => {
        return (
          <>
            <Popconfirm
              title="Are you sure to delete this household?"
              onConfirm={async (e) => {
                e?.stopPropagation();
                await dispatch(deleteHouseholdAction(record._id));
              }}
              onCancel={(e) => {
                e?.stopPropagation();
              }}
              okText="Yes"
              cancelText="No"
            >
              <a href="#" onClick={(e) => e.stopPropagation()}>
                Delete
              </a>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  return (
    <Fragment>
      <Card
        bordered={false}
        title="Households"
        extra={
          <Button
            size="small"
            type="dashed"
            onClick={() => setAddModalVisible(true)}
          >
            Add Household
          </Button>
        }
      >
        <Table
          loading={loading}
          size="small"
          rowKey={(record) => {
            return record._id;
          }}
          onRow={(record) => {
            return {
              onClick: async () => {
                dispatch(setActiveHouseholdAction(record));
                await dispatch(getHouseholdPlansAction(record._id));
                history.push(plansRoute);
              },
            };
          }}
          pagination={false}
          columns={tableColumns}
          dataSource={tableData}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <HouseholdTableCard
                        title="Client"
                        person={record.client}
                      />
                    </Col>
                    <Col span={12}>
                      <HouseholdTableCard
                        title="Partner"
                        person={record.partner!}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <HouseholdDependantsTable
                        dependents={record.dependents!}
                        title="Dependants"
                      />
                    </Col>
                  </Row>
                </>
              );
            },
          }}
        />
      </Card>
      <AddModal
        addModalVisible={addModalVisible}
        setAddModalVisible={setAddModalVisible}
        title="Add Household"
      />
      <DeleteHouseholdModal
        householdId={selectedHouseholdId}
        deleteModalVisible={deleteModalVisible}
        setDeleteModalVisible={setDeleteModalVisible}
      />
    </Fragment>
  );
};

export default HouseholdsTable;
