import { Divider, Menu, Table } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Link } from "react-router-dom";
import { forecastRoute, inputsRoute } from "../../../routes/navRoutes";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import IPlan from "../../../interface/IPlan";
import { setActivePlanAction } from "../../../redux/Plan";
import { getDataAction } from "../../../redux/Data";

const SideNav = () => {
  const dispatch = useDispatch();

  const plans = useSelector((state: RootStateOrAny) => state.planReducer);
  const loading = useSelector((state: RootStateOrAny) => state.loadingReducer);

  const columns = [
    {
      title: "Plans",
      dataIndex: "name",
      key: "name",
    },
  ];

  return (
    <Sider style={{ background: "white" }}>
      <Menu mode="inline" activeKey="5" style={{ height: "100%" }}>
        {/* <Menu.Item key="1">
          <Link to={inflowsRoute} onClick={() => setMenuKey(["1"])}>
            Inflows
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to={outflowsRoute} onClick={() => setMenuKey(["2"])}>
            Outflows
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to={assetsRoute} onClick={() => setMenuKey(["3"])}>
            Assets
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to={liabilityRoute} onClick={() => setMenuKey(["4"])}>
            Liabilities
          </Link>
        </Menu.Item> */}
        <Menu.Item key="5">
          <Link to={inputsRoute}>Inputs</Link>
        </Menu.Item>
        <Menu.Item key="6">
          <Link to={forecastRoute}>Forecast</Link>
        </Menu.Item>
      </Menu>

      <Divider plain>Plans</Divider>

      <Table
        columns={columns}
        dataSource={plans}
        size="small"
        pagination={false}
        bordered={false}
        showHeader={false}
        loading={loading}
        onRow={(record: IPlan, rowIndex: any) => {
          return {
            style: {
              cursor: "pointer",
            },
            onClick: async () => {
              await dispatch(setActivePlanAction(record));
              await dispatch(getDataAction(record._id!));
              // history.push(inputsRoute);
            },
          };
        }}
      />
    </Sider>
  );
};

export default SideNav;
