import {
  Button,
  Card,
  Row,
  Space,
  Table,
  Form,
  Input,
  InputNumber,
  Col,
  DatePicker,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import IData from "../../../interface/IData";
import Modal from "antd/lib/modal/Modal";
import { IFlow } from "../../../interface/IFlow";
import moment from "moment";
import axios from "axios";
import { apiPlanRoute } from "../../../routes/apiRoutes";
import { getDataAction } from "../../../redux/Data";
import { setLoadingAction } from "../../../redux/General/Loading";
import { IAsset } from "../../../interface/IAsset";
import { ILiability } from "../../../interface/ILiability";
import { numberFormat } from "highcharts";
import { currencySymbol } from "../../../components/common/currencySymbol";
import {
  CreateLiabilityDto,
  CreateLoanInputDto,
  UpdateLoanInputDto,
} from "@advicefront/plan-api";
import calcRate from "../../../helper/data/formulae/calcRate";
import calcLoanPv from "../../../helper/data/formulae/calcLoanPv";

//@ts-ignore
import formulajs from "@formulajs/formulajs";
import {
  createInputsLoanAction,
  deleteInputsAcion,
  updateInputsAction,
} from "../../../redux/Inputs";

const LiquidAssets = () => {
  const dispatch = useDispatch();

  const data: IData = useSelector((state: RootStateOrAny) => state.dataReducer);
  const loading: boolean = useSelector(
    (state: RootStateOrAny) => state.loadingReducer
  );

  const [newModalVisible, setNewModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [editModalVisible, setEditModalVisible] = useState(false);

  const [newLiability, setNewLiability] = useState<CreateLoanInputDto>({
    label: "",
    loan: {
      balance: 0,
      endYear: 2021,
      interestOnly: false,
      label: "",
      rate: 0,
      startYear: 2021,
      years: 0,
    },
    loanPayment: {
      amount: 0,
      endYear: 2021,
      growth: 0,
      label: "",
      startYear: 2021,
      payAllPeriod: 2021,
    },
  });

  const [selectedLiability, setSelectedLiability] =
    useState<UpdateLoanInputDto>({
      label: "",
      loan: {
        balance: 0,
        endYear: 2021,
        interestOnly: false,
        label: "",
        rate: 0,
        startYear: 2021,
        years: 0,
      },
      loanPayment: {
        amount: 0,
        endYear: 2021,
        growth: 0,
        label: "",
        startYear: 2021,
        payAllPeriod: 2021,
      },
    });

  const [selectedLiabilityIndex, setSelectedLiabilityIndex] = useState(0);

  const [form] = Form.useForm();

  const columns = [
    { title: "Name", dataIndex: "label", key: "name" },
    {
      title: "Loan Balance",
      dataIndex: "balance",
      key: "balance",
      render: (record: number) =>
        currencySymbol + numberFormat(record, 0, ".", ","),
    },
    {
      title: "Growth",
      dataIndex: "rate",
      key: "rate",
      render: (record: number) => numberFormat(record * 100, 2) + "%",
    },
    { title: "Start Year", dataIndex: "startYear", key: "startYear" },
    { title: "End Year", dataIndex: "endYear", key: "endYear" },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (value: any, record: any, recordIndex: number) => (
        <>
          <a
            onClick={() => {
              setSelectedLiability(data.inputs.liabilities[recordIndex]);
              setEditModalVisible(true);
            }}
          >
            Edit
          </a>
          {"              "}
          <a
            onClick={() => {
              setSelectedLiabilityIndex(recordIndex);

              setDeleteModalVisible(true);
            }}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  const tableData = data.inputs.liabilities.map((l) => l.loan);

  return (
    <Layout>
      <Card
        bordered={false}
        title="Liability"
        extra={
          <Button type="primary" onClick={() => setNewModalVisible(true)}>
            Add Liability
          </Button>
        }
      >
        <Table
          rowKey={(record, recordIndex) => {
            return recordIndex + "liability";
          }}
          dataSource={tableData}
          columns={columns}
          expandable={{
            expandedRowRender: (record, recordIndex) => (
              <>
                <Row>
                  <Col span={24}>
                    <Card bordered={false} title="Expenditures">
                      <Table
                        pagination={false}
                        columns={[
                          {
                            title: "Name",
                            dataIndex: "label",
                            key: recordIndex + "AssetInflowName",
                          },
                          {
                            title: "Amount",
                            dataIndex: "amount",
                            key: recordIndex + "assetinflowamount",
                            render: (record) =>
                              currencySymbol +
                              numberFormat(record, 0, ".", ","),
                          },
                          {
                            title: "Growth",
                            dataIndex: "growth",
                            key: recordIndex + "assetinflowgrowth",
                            render: (record) =>
                              numberFormat(record * 100, 2) + "%",
                          },
                          {
                            title: "Start Year",
                            dataIndex: "startYear",
                            key: recordIndex + "assetInflowStartYear",
                          },
                          {
                            title: "End Year",
                            dataIndex: "endYear",
                            key: recordIndex + "assetInflowendYear",
                          },
                          {
                            title: "Pay all period",
                            dataIndex: "payAllPeriod",
                            key: recordIndex + "assetoutflowPayallperiod",
                          },
                        ]}
                        dataSource={[
                          {
                            key: "1",
                            label:
                              data.inputs.liabilities[recordIndex].loanPayment
                                .label,
                            amount:
                              data.inputs.liabilities[recordIndex].loanPayment
                                .amount,
                            growth:
                              data.inputs.liabilities[recordIndex].loanPayment
                                .growth,
                            startYear:
                              data.inputs.liabilities[recordIndex].loanPayment
                                .startYear,
                            endYear:
                              data.inputs.liabilities[recordIndex].loanPayment
                                .endYear,
                            payAllPeriod:
                              data.inputs.liabilities[recordIndex].loanPayment
                                .payAllPeriod,
                          },
                        ]}
                      ></Table>
                    </Card>
                  </Col>
                </Row>
              </>
            ),
            rowExpandable: (record) => record.label !== "Not Expandable",
          }}
        />
        ,
      </Card>

      {/* New Liability Modal */}
      <Modal
        title={"Add Liability"}
        visible={newModalVisible}
        okText={"Save"}
        onOk={async () => {
          const clone: CreateLoanInputDto = JSON.parse(
            JSON.stringify(newLiability)
          );

          const pv = calcLoanPv(
            newLiability.loan.balance,
            newLiability.loan.rate,
            newLiability.loan.startYear
          );

          clone.loanPayment.growth = calcRate(
            newLiability.loan.startYear,
            newLiability.loan.endYear,
            -newLiability.loanPayment.amount,
            pv
          );

          await dispatch(createInputsLoanAction(data._id!, clone));

          form.resetFields();
          setNewLiability({
            label: "",
            loan: {
              balance: 0,
              endYear: 2021,
              interestOnly: false,
              label: "",
              rate: 0,
              startYear: 0,
              years: 2021,
            },
            loanPayment: {
              amount: 0,
              endYear: 2021,
              growth: 0,
              label: "",
              startYear: 2021,
              payAllPeriod: 0,
            },
          });

          setNewModalVisible(false);
        }}
        onCancel={() => {
          setNewModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            form={form}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{}}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name of Outflow" }]}
                >
                  <Input
                    value={newLiability.label}
                    onChange={(e) => {
                      setNewLiability({
                        ...newLiability,
                        label: e.target.value,
                        loan: { ...newLiability.loan, label: e.target.value },
                        loanPayment: {
                          ...newLiability.loanPayment,
                          label: "Loan Payment - " + e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="amount"
                  label="Loan Balance"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${newLiability.loan.balance}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewLiability({
                        ...newLiability,
                        loan: {
                          ...newLiability.loan,
                          balance: +e.target.value.replace(/\$\s?|(,*)/g, ""),
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="growth"
                  label="Rate"
                  rules={[
                    { required: true, message: "Inflation rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${newLiability.loan.rate * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewLiability({
                        ...newLiability,
                        loan: {
                          ...newLiability.loan,
                          rate:
                            parseFloat(e.target.value.replace("%", "")) / 100,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="startYear"
                  label="Start Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${newLiability.loan.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewLiability({
                        ...newLiability,
                        loan: {
                          ...newLiability.loan,
                          startYear: +dateString,
                        },
                        loanPayment: {
                          ...newLiability.loanPayment,
                          startYear: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="endYear"
                  label="End Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${newLiability.loan.endYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewLiability({
                        ...newLiability,
                        loan: {
                          ...newLiability.loan,
                          endYear: +dateString,
                        },
                        loanPayment: {
                          ...newLiability.loanPayment,
                          endYear: +dateString,
                          payAllPeriod: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>
                <br />
                <Form.Item
                  name="loanpayment"
                  label="Loan Payment"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${newLiability.loanPayment.amount}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewLiability({
                        ...newLiability,
                        loanPayment: {
                          ...newLiability.loanPayment,
                          amount: +e.target.value.replace(/\$\s?|(,*)/g, ""),
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>

      {/* Edit Liability Modal */}
      <Modal
        title={"Edit Liability"}
        visible={editModalVisible}
        okText={"Save"}
        onOk={async () => {
          const clone: CreateLoanInputDto = JSON.parse(
            JSON.stringify(selectedLiability)
          );

          const pv = calcLoanPv(
            selectedLiability.loan!.balance,
            selectedLiability.loan!.rate,
            selectedLiability.loan!.startYear
          );

          clone.loanPayment.growth = calcRate(
            selectedLiability.loan!.startYear,
            selectedLiability.loan!.endYear,
            -selectedLiability.loanPayment!.amount,
            pv
          );

          await dispatch(
            updateInputsAction(
              data._id!,
              data.inputs.liabilities[selectedLiabilityIndex]._id,
              clone
            )
          );

          form.resetFields();
          setSelectedLiability({
            label: "",
            loan: {
              balance: 0,
              endYear: 2021,
              interestOnly: false,
              label: "",
              rate: 0,
              startYear: 0,
              years: 2021,
            },
            loanPayment: {
              amount: 0,
              endYear: 2021,
              growth: 0,
              label: "",
              startYear: 2021,
              payAllPeriod: 0,
            },
          });

          setEditModalVisible(false);
        }}
        onCancel={() => {
          setEditModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            form={form}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{}}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Name"
                  rules={[{ required: true, message: "Name of Outflow" }]}
                >
                  <Input
                    value={selectedLiability.label}
                    onChange={(e) => {
                      setSelectedLiability({
                        ...selectedLiability,
                        label: e.target.value,
                        loan: {
                          ...selectedLiability.loan!,
                          label: e.target.value,
                        },
                        loanPayment: {
                          ...selectedLiability.loanPayment!,
                          label: "Loan Payment - " + e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Loan Balance"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${selectedLiability.loan!.balance}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setSelectedLiability({
                        ...selectedLiability,
                        loan: {
                          ...selectedLiability.loan!,
                          balance: +e.target.value.replace(/\$\s?|(,*)/g, ""),
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Rate"
                  rules={[
                    { required: true, message: "Inflation rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${selectedLiability.loan!.rate * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setSelectedLiability({
                        ...selectedLiability,
                        loan: {
                          ...selectedLiability.loan!,
                          rate:
                            parseFloat(e.target.value.replace("%", "")) / 100,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Start Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${selectedLiability.loan!.startYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setSelectedLiability({
                        ...selectedLiability,
                        loan: {
                          ...selectedLiability.loan!,
                          startYear: +dateString,
                        },
                        loanPayment: {
                          ...selectedLiability.loanPayment!,
                          startYear: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="End Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(
                      `${selectedLiability.loan!.endYear}`,
                      "YYYY"
                    )}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setSelectedLiability({
                        ...selectedLiability,
                        loan: {
                          ...selectedLiability.loan!,
                          endYear: +dateString,
                        },
                        loanPayment: {
                          ...selectedLiability.loanPayment!,
                          endYear: +dateString,
                          payAllPeriod: +dateString,
                        },
                      });
                    }}
                  />
                </Form.Item>
                <br />
                <Form.Item
                  label="Loan Payment"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${selectedLiability.loanPayment!.amount}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setSelectedLiability({
                        ...selectedLiability,
                        loanPayment: {
                          ...selectedLiability.loanPayment!,
                          amount: +e.target.value.replace(/\$\s?|(,*)/g, ""),
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>

      {/* Delete Inflow Modal */}
      <Modal
        title="Delete inflow"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={async () => {
          await dispatch(
            deleteInputsAcion(
              data._id!,
              data.inputs.liabilities[selectedLiabilityIndex]._id
            )
          );
          setDeleteModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
      >
        Are you sure you want to delete the Liability?
      </Modal>
    </Layout>
  );
};

export default LiquidAssets;
