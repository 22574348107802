import {
  Button,
  Card,
  Row,
  Table,
  Form,
  Input,
  InputNumber,
  Col,
  DatePicker,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import IData from "../../../interface/IData";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";

import {
  CreateBankAccountInputDto,
  CreateCashInDto,
  CreateCashOutDto,
  UpdateBankAccountInputDto,
} from "@advicefront/plan-api";
import {
  createInputsBankAccountAction,
  deleteInputsAcion,
  updateInputsAction,
} from "../../../redux/Inputs";
import { numberFormat } from "highcharts";
import { currencySymbol } from "../../../components/common/currencySymbol";

const LiquidAssets = () => {
  const dispatch = useDispatch();

  const data: IData = useSelector((state: RootStateOrAny) => state.dataReducer);
  const loading: boolean = useSelector(
    (state: RootStateOrAny) => state.loadingReducer
  );

  const [newModalVisible, setNewModalVisible] = useState(false);
  const [assetEditModalVisible, setAssetEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [newInflowModalVisible, setNewInflowModalVisible] = useState(false);
  const [newOutflowModalVisible, setNewOutflowModal] = useState(false);

  const [newInflowFlow, setNewInflow] = useState<CreateCashInDto>({
    amount: 0,
    endYear: 2021,
    growth: 0,
    label: "",
    startYear: 2021,
  });

  const [newOutflow, setNewOutflow] = useState<CreateCashOutDto>({
    amount: 0,
    endYear: 9999,
    growth: 0,
    label: "",
    startYear: 2021,
    payAllPeriod: 0,
  });

  const [newAsset, setNewAsset] = useState<CreateBankAccountInputDto>({
    label: "",
    bankAccount: {
      currentValue: 0,
      dividendsYield: 0,
      endYear: 2021,
      growth: 0,
      initialValue: 0,
      interestYield: 0,
      label: "",
      reinvestedYield: 0,
      startYear: 2021,
    },
    withdrawals: [],
    contributions: [],
  });

  const [selectedAsset, setSelectedAsset] = useState<UpdateBankAccountInputDto>(
    {
      label: "",
      bankAccount: {
        currentValue: 0,
        dividendsYield: 0,
        endYear: 9999,
        growth: 0,
        initialValue: 0,
        interestYield: 0,
        label: "",
        reinvestedYield: 0,
        startYear: 2021,
      },
      withdrawals: [],
      contributions: [],
    }
  );

  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);

  const [form] = Form.useForm();
  const [formInflow] = Form.useForm();
  const [formOutflow] = Form.useForm();

  const columns = [
    {
      title: "Name",
      dataIndex: "label",
      key: "name",
    },
    {
      title: "Balance",
      dataIndex: "initialValue",
      key: "amount",
      render: (record: any) =>
        currencySymbol + numberFormat(record, 0, ".", ","),
    },
    {
      title: "Growth",
      dataIndex: "growth",
      key: "growth",
      render: (record: any) => numberFormat(record * 100, 2, ".", ",") + "%",
    },

    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (value: any, record: any, recordIndex: number) => (
        <>
          <a
            onClick={() => {
              setSelectedAsset(data.inputs.bankAccounts[recordIndex]);
              setSelectedAssetIndex(recordIndex);
              setAssetEditModalVisible(true);
            }}
          >
            Edit
          </a>
          {"       "}
          <a
            onClick={() => {
              // setSelectedAsset(data.inputs.bankAccounts[recordIndex]);
              setSelectedAssetIndex(recordIndex);

              setDeleteModalVisible(true);
            }}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  // const tableData = data.liquidAssets;

  return (
    <Layout>
      <Card
        bordered={false}
        title="Savings and Investments"
        extra={
          <Button type="primary" onClick={() => setNewModalVisible(true)}>
            Add Account
          </Button>
        }
      >
        <Table
          rowKey={(record) => {
            return record._id!;
          }}
          columns={columns}
          expandable={{
            expandedRowRender: (record, recordIndex) => (
              <>
                <Row>
                  <Col span={24}>
                    <Card
                      bordered={false}
                      title="Withdrawals"
                      extra={
                        <Button
                          onClick={() => {
                            setSelectedAsset(
                              data.inputs.bankAccounts[recordIndex]
                            );

                            setSelectedAssetIndex(recordIndex);
                            console.log(recordIndex);

                            setNewInflowModalVisible(true);
                          }}
                          size="small"
                        >
                          Add Inflow
                        </Button>
                      }
                    >
                      <Table
                        size="small"
                        loading={loading}
                        pagination={false}
                        columns={[
                          {
                            title: "Name",
                            dataIndex: "label",
                            key: recordIndex + "AssetInflowName",
                          },
                          {
                            title: "Amount",
                            dataIndex: "amount",
                            key: recordIndex + "assetinflowamount",
                            render: (record) =>
                              currencySymbol +
                              numberFormat(record, 0, ".", ","),
                          },
                          {
                            title: "Growth",
                            dataIndex: "growth",
                            key: recordIndex + "assetinflowgrowth",
                            render: (record) =>
                              numberFormat(record * 100, 2, ".", ",") + "%",
                          },
                          {
                            title: "Start Year",
                            dataIndex: "startYear",
                            key: recordIndex + "assetInflowStartYear",
                          },
                          {
                            title: "End Year",
                            dataIndex: "endYear",
                            key: recordIndex + "assetInflowendYear",
                          },
                          {
                            title: "Actions",
                            render: (value, flowRecord, flowIndex) => (
                              <>
                                <a
                                  type="link"
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    const clone = {
                                      ...data.inputs.bankAccounts[recordIndex],
                                    };
                                    clone.withdrawals.splice(flowIndex, 1);
                                    await dispatch(
                                      updateInputsAction(
                                        data._id!,
                                        data.inputs.bankAccounts[recordIndex]
                                          ._id,
                                        clone
                                      )
                                    );
                                  }}
                                >
                                  Delete
                                </a>
                              </>
                            ),
                          },
                        ]}
                        dataSource={
                          data.inputs.bankAccounts[recordIndex].withdrawals
                        }
                      ></Table>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card
                      bordered={false}
                      title="Contributions"
                      extra={
                        <Button
                          onClick={() => {
                            setSelectedAsset(
                              data.inputs.bankAccounts[recordIndex]
                            );

                            setSelectedAssetIndex(recordIndex);

                            setNewOutflowModal(true);
                          }}
                          size="small"
                        >
                          Add Outflow
                        </Button>
                      }
                    >
                      <Table
                        size="small"
                        loading={loading}
                        pagination={false}
                        columns={[
                          {
                            title: "Name",
                            dataIndex: "label",
                            key: recordIndex + "AssetInflowName",
                          },
                          {
                            title: "Amount",
                            dataIndex: "amount",
                            key: recordIndex + "assetinflowamount",
                            render: (record) =>
                              currencySymbol +
                              numberFormat(record, 0, ".", ","),
                          },
                          {
                            title: "Growth",
                            dataIndex: "growth",
                            key: recordIndex + "assetinflowgrowth",
                            render: (record) =>
                              numberFormat(record * 100, 2, ".", ",") + "%",
                          },
                          {
                            title: "Start Year",
                            dataIndex: "startYear",
                            key: recordIndex + "assetInflowStartYear",
                          },
                          {
                            title: "End Year",
                            dataIndex: "endYear",
                            key: recordIndex + "assetInflowendYear",
                          },
                          {
                            title: "Actions",
                            render: (
                              value,
                              contribution,
                              contributionIndex
                            ) => (
                              <>
                                <a
                                  type="link"
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    const clone = {
                                      ...data.inputs.bankAccounts[recordIndex],
                                    };
                                    clone.contributions.splice(
                                      contributionIndex,
                                      1
                                    );
                                    await dispatch(
                                      updateInputsAction(
                                        data._id!,
                                        data.inputs.bankAccounts[recordIndex]
                                          ._id,
                                        clone
                                      )
                                    );
                                  }}
                                >
                                  Delete
                                </a>
                              </>
                            ),
                          },
                        ]}
                        dataSource={
                          data.inputs.bankAccounts[recordIndex].contributions
                        }
                      ></Table>
                    </Card>
                  </Col>
                </Row>
              </>
            ),
            rowExpandable: (record) => record.label !== "Not Expandable",
          }}
          dataSource={data.inputs.bankAccounts.map((ba) => ba.bankAccount)}
        />
      </Card>

      {/* New Asset Modal */}
      <Modal
        title={"Add Account"}
        visible={newModalVisible}
        okText={"Save"}
        onOk={async () => {
          await dispatch(
            createInputsBankAccountAction(data._id!, {
              label: newAsset.label,
              bankAccount: newAsset.bankAccount,
              contributions: [],
              withdrawals: [],
            })
          );
          form.resetFields();
          setNewAsset({
            label: "",
            bankAccount: {
              currentValue: 0,
              dividendsYield: 0,
              endYear: 2021,
              growth: 0,
              initialValue: 0,
              interestYield: 0,
              label: "",
              reinvestedYield: 0,
              startYear: 2021,
            },
            withdrawals: [],
            contributions: [],
          });

          setNewModalVisible(false);
        }}
        onCancel={() => {
          setNewModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            form={form}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{}}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name of Outflow" }]}
                >
                  <Input
                    value={newAsset.label}
                    onChange={(e) => {
                      setNewAsset({
                        ...newAsset,
                        label: e.target.value,
                        bankAccount: {
                          ...newAsset.bankAccount,
                          label: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="amount"
                  label="Balance"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${newAsset.bankAccount.initialValue}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewAsset({
                        ...newAsset,
                        bankAccount: {
                          ...newAsset.bankAccount,
                          initialValue: +e.target.value.replace(
                            /\$\s?|(,*)/g,
                            ""
                          ),
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="growth"
                  label="Growth"
                  rules={[
                    { required: true, message: "Inflation rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${newAsset.bankAccount.growth * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewAsset({
                        ...newAsset,
                        bankAccount: {
                          ...newAsset.bankAccount,
                          growth:
                            parseFloat(e.target.value.replace("%", "")) / 100,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>

      {/* Edit Asset Modal */}
      <Modal
        title={"Edit Account"}
        visible={assetEditModalVisible}
        okText={"Save"}
        onOk={async () => {
          await dispatch(
            updateInputsAction(
              data._id!,
              data.inputs.bankAccounts[selectedAssetIndex]._id,
              selectedAsset
            )
          );

          form.resetFields();

          setSelectedAsset({
            label: "",
            bankAccount: {
              currentValue: 0,
              dividendsYield: 0,
              endYear: 9999,
              growth: 0,
              initialValue: 0,
              interestYield: 0,
              label: "",
              reinvestedYield: 0,
              startYear: 2021,
            },
            withdrawals: [],
            contributions: [],
          });
          setAssetEditModalVisible(false);
        }}
        onCancel={() => {
          setAssetEditModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{}}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Name"
                  rules={[{ required: true, message: "Name of Outflow" }]}
                >
                  <Input
                    value={selectedAsset.label}
                    onChange={(e) => {
                      setSelectedAsset({
                        ...selectedAsset,
                        label: e.target.value,
                        bankAccount: {
                          ...selectedAsset.bankAccount!,
                          label: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Balance"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${selectedAsset.bankAccount?.initialValue}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setSelectedAsset({
                        ...selectedAsset,
                        bankAccount: {
                          ...selectedAsset.bankAccount!,
                          initialValue: +e.target.value.replace(
                            /\$\s?|(,*)/g,
                            ""
                          ),
                        },
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Growth"
                  rules={[
                    { required: true, message: "Inflation rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${selectedAsset.bankAccount!.growth * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setSelectedAsset({
                        ...selectedAsset,
                        bankAccount: {
                          ...selectedAsset.bankAccount!,
                          growth:
                            parseFloat(e.target.value.replace("%", "")) / 100,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>

      {/* Delete Asset Modal */}
      <Modal
        title="Delete inflow"
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={async () => {
          await dispatch(
            deleteInputsAcion(
              data._id!,
              data.inputs.bankAccounts[selectedAssetIndex]._id
            )
          );

          setDeleteModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
      >
        Are you sure you want to delete the account?
      </Modal>

      {/* New Inflow Modal */}
      <Modal
        title={"Add Inflow"}
        visible={newInflowModalVisible}
        okText={"Save"}
        onOk={async () => {
          const clone = { ...selectedAsset };
          clone.withdrawals!.push(newInflowFlow);

          await dispatch(
            updateInputsAction(
              data._id!,
              data.inputs.bankAccounts[selectedAssetIndex]._id,
              clone
            )
          );

          form.resetFields();
          formInflow.resetFields();

          setNewInflow({
            amount: 0,
            endYear: 2021,
            growth: 0,
            label: "",
            startYear: 2021,
          });

          setNewInflowModalVisible(false);
        }}
        onCancel={() => {
          setNewInflowModalVisible(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            form={formInflow}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{}}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name of Outflow" }]}
                >
                  <Input
                    value={newAsset.label}
                    onChange={(e) => {
                      setNewInflow({
                        ...newInflowFlow,
                        label: e.target.value,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="amount"
                  label="Amount"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${newInflowFlow.amount}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewInflow({
                        ...newInflowFlow,
                        amount: +e.target.value.replace(/\$\s?|(,*)/g, ""),
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="growth"
                  label="Growth"
                  rules={[
                    { required: true, message: "Inflation rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${newInflowFlow.growth * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewInflow({
                        ...newInflowFlow,
                        growth:
                          parseFloat(e.target.value.replace("%", "")) / 100,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="startYear"
                  label="Start Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(`${newInflowFlow.startYear}`, "YYYY")}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewInflow({
                        ...newInflowFlow,
                        startYear: +dateString,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="endYear"
                  label="End Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(`${newInflowFlow.startYear}`, "YYYY")}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewInflow({
                        ...newInflowFlow,
                        endYear: +dateString,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>

      {/* New Outflow Modal */}
      <Modal
        title={"Add Outflow"}
        visible={newOutflowModalVisible}
        okText={"Save"}
        onOk={async () => {
          const clone = { ...selectedAsset };
          clone.contributions!.push(newOutflow);

          await dispatch(
            updateInputsAction(
              data._id!,
              data.inputs.bankAccounts[selectedAssetIndex]._id,
              clone
            )
          );

          form.resetFields();
          formInflow.resetFields();

          formOutflow.resetFields();
          setNewOutflow({
            amount: 0,
            endYear: 2021,
            growth: 0,
            label: "",
            startYear: 2021,
            payAllPeriod: 0,
          });

          setNewOutflowModal(false);
        }}
        onCancel={() => {
          setNewOutflowModal(false);
        }}
        cancelButtonProps={{
          loading,
        }}
        okButtonProps={{
          loading,
        }}
        width="500px"
      >
        <Row>
          <Form
            form={formOutflow}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{}}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name of Outflow" }]}
                >
                  <Input
                    value={newAsset.label}
                    onChange={(e) => {
                      setNewOutflow({
                        ...newOutflow,
                        label: e.target.value,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="amount"
                  label="Amount"
                  rules={[{ required: true, message: "Amount of Outflow" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`${newOutflow.amount}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewOutflow({
                        ...newOutflow,
                        amount: +e.target.value.replace(/\$\s?|(,*)/g, ""),
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="growth"
                  label="Growth"
                  rules={[
                    { required: true, message: "Inflation rate is required" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={2}
                    style={{ width: "100%" }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value!.replace("%", "")}
                    value={`${newOutflow.growth * 100}`}
                    className="custom-input-fields"
                    onBlur={(e) => {
                      setNewOutflow({
                        ...newOutflow,
                        growth:
                          parseFloat(e.target.value.replace("%", "")) / 100,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="startYear"
                  label="Start Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(`${newOutflow.startYear}`, "YYYY")}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewOutflow({
                        ...newOutflow,
                        startYear: +dateString,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="endYear"
                  label="End Year"
                  rules={[{ required: true, message: "Please select a year" }]}
                >
                  <DatePicker
                    picker="year"
                    name="year"
                    defaultValue={moment(`${newOutflow.startYear}`, "YYYY")}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => {
                      setNewOutflow({
                        ...newOutflow,
                        endYear: +dateString,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>
    </Layout>
  );
};

export default LiquidAssets;
