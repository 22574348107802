import axios from "axios";
import { apiPlanRoute, apiRoute } from "../../routes/apiRoutes";
import IPlan from "../../interface/IPlan";

import { setLoadingAction } from "../General/Loading";

import { CreatePlanDto, PlansApi, UpdatePlanDto } from "@advicefront/plan-api";
import IHouseholds from "../../interface/IHouseholds";

const plansApi = new PlansApi({
  isJsonMime: () => true,
  basePath: apiRoute,
});

export const addPlanAction =
  (newPlanObect: CreatePlanDto) => async (dispatch: any) => {
    dispatch(setLoadingAction(true));
    const res = await plansApi.plansControllerCreate(newPlanObect);
    dispatch({
      type: "ADD_PLAN",
      payload: res.data,
    });

    dispatch(setLoadingAction(false));
  };

export const getPlansAction = () => async (dispatch: any) => {
  dispatch(setLoadingAction(true));
  const res = await plansApi.plansControllerFindAll();
  dispatch({
    type: "GET_PLANS",
    payload: res.data,
  });
  dispatch(setLoadingAction(false));
};

export const getHouseholdPlansAction =
  (householdId: string) => async (dispatch: any) => {
    dispatch(setLoadingAction(true));
    const res = await plansApi.plansControllerFindAll();

    const householdPlans = res.data.filter((h: any) => {
      return h.household === householdId;
    });

    dispatch({
      type: "GET_PLANS",
      payload: householdPlans,
    });
    dispatch(setLoadingAction(false));
  };

export const updatePlanAction =
  (planId: string, plan: UpdatePlanDto) => async (dispatch: any) => {
    try {
      dispatch(setLoadingAction(true));
      const res = await plansApi.plansControllerUpdate(planId, plan);
      dispatch(getPlansAction());
      dispatch(setLoadingAction(false));
    } catch (err: any) {
      console.log(err.message);
      dispatch(setLoadingAction(false));
    }
  };

export const deletePlanAction = (planId: string) => async (dispatch: any) => {
  try {
    dispatch(setLoadingAction(true));
    const res = await plansApi.plansControllerRemove(planId);
    dispatch(getPlansAction());
    dispatch(setLoadingAction(false));
  } catch (err: any) {
    console.log(err.message);
    dispatch(setLoadingAction(false));
  }
};

export const setActivePlanAction = (plan: IPlan) => (dispatch: any) => {
  dispatch({
    type: "SET_ACTIVE_PLAN",
    payload: plan,
  });
};

export const activePlanReducer = (
  state: IPlan = {
    name: "",
    _id: "",
    createdAt: "",
    updatedAt: "",
    endYear: 2040,
    startYear: 2021,
  },
  action: any
) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_ACTIVE_PLAN":
      return (state = payload);

    default:
      return state;
  }
};

const initialPlanReducerState: IPlan[] = [];

export const planReducer = (state = initialPlanReducerState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "ADD_PLAN":
      return (state = [...state, payload]);
    case "GET_PLANS":
      return (state = payload);
    case "DELETE_PLAN":
      let newState = [...state];
      newState = state.filter((plan) => {
        return payload._id !== plan._id;
      });
      return (state = newState);

    default:
      return state;
  }
};
